import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateService } from "../../store/thunks/servicesThunk"; // Assuming you have this action

export default function EditServiceModal({ service, onClose, onCancel }) {
    const dispatch = useDispatch();
    const [serviceData, setServiceData] = useState({
        description: service.description,
        serviceCode: service.serviceCode,
        price: service.price,
        length: 0, // New field for length in minutes
        incomeAccount: service.incomeAccount,
        isTaxable: service.tax.isTaxable,
        taxRate: service.tax.taxRate,
    });

    useEffect(() => {
        // ... (Your existing useEffect for handling Escape key)
    }, [onClose]);

    const handleSave = async (event) => {
        event.preventDefault();

        // Validate required fields (adjust as needed)
        if (
            !serviceData.description ||
            !serviceData.price ||
            serviceData.length <= 0
        ) {
            alert("Service description, price, and length are required.");
            return;
        }

        dispatch(updateService({ id: service._id, serviceData }));
        onClose();
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setServiceData((prevData) => ({
            ...prevData,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
            <div
                className="fixed inset-0 bg-gray-900 opacity-50"
                onClick={onCancel}
            />
            <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
                <form onSubmit={handleSave}>
                    {/* Header */}
                    <div className="border-b border-gray-900/10 pb-6 mb-6">
                        <h2 className="text-lg font-semibold leading-7 text-gray-900">
                            Edit Service
                        </h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Use this form to edit the service information.
                        </p>
                    </div>

                    {/* Service Information */}
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                        <div className="sm:col-span-2">
                            <label
                                htmlFor="serviceDescription"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Description
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="description"
                                    id="serviceDescription"
                                    value={serviceData.description}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1">
                            <label
                                htmlFor="serviceCode"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Service Code
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="serviceCode"
                                    id="serviceCode"
                                    value={serviceData.serviceCode}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1">
                            <label
                                htmlFor="servicePrice"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Price
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number" // Use type="number" for price
                                    name="price"
                                    id="servicePrice"
                                    value={serviceData.price}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* New field for length */}
                        <div className="sm:col-span-1">
                            <label
                                htmlFor="serviceLength"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Length (minutes)
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="length"
                                    id="serviceLength"
                                    value={serviceData.length}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label
                                htmlFor="serviceIncomeAccount"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Income Account
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="incomeAccount"
                                    id="serviceIncomeAccount"
                                    value={serviceData.incomeAccount}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1 flex items-center">
                            <input
                                id="isTaxable"
                                name="isTaxable"
                                type="checkbox"
                                checked={serviceData.isTaxable}
                                onChange={handleChange}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label
                                htmlFor="isTaxable"
                                className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                            >
                                Is Taxable
                            </label>
                        </div>

                        {serviceData.isTaxable && (
                            <div className="sm:col-span-1">
                                <label
                                    htmlFor="taxRate"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Tax Rate
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="number"
                                        name="taxRate"
                                        id="taxRate"
                                        value={serviceData.taxRate}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-sm font-semibold leading-6 text-gray-900"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
