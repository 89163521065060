import React, { useState } from "react";
import GoogleMapsAutocomplete from "../../applicationUi/components/GoogleAutoComplete";
import QuickAddUserModal from "../../modals/QuickAddUserModal";

function ReportsPage() {
  const [isQuickAddUserModalOpen, setIsQuickAddUserModalOpen] = useState(false);

  const handlePlaceSelected = (place) => {
    // Handle the selected place object here
    console.log(place); // For example, log the place details
  };

  const openQuickAddUserModal = () => {
    setIsQuickAddUserModalOpen(true);
  };

  const closeQuickAddUserModal = () => {
    setIsQuickAddUserModalOpen(false);
  };

  return (
    <>
      <div>
        <div className="mb-10"></div>
        This is the HR Page
        <button
          type="button"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={openQuickAddUserModal}>
          Quick Add User
        </button>
      </div>
      <div>
        <GoogleMapsAutocomplete onPlaceSelected={handlePlaceSelected} />
      </div>
      {/* Modals */}
      <>
        {isQuickAddUserModalOpen && <QuickAddUserModal onClose={closeQuickAddUserModal} />}
      </>
    </>
  );
}

export default ReportsPage;
