import React, { useState } from "react";
import { useDispatch } from "react-redux"; // Import useDispatch hook
import { updateInvoiceStatus } from "../../store/thunks/invoiceThunk";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/hearsay_logo.png";

// InvoiceGenerator Component
const InvoiceGenerator = ({ data, refetchInvoices }) => {
  const [viewMode, setViewMode] = useState(false);
  const dispatch = useDispatch();

  const handleLog = () => {
    console.log("The data passed into InvoiceGenerator", data);
  };

  const handleNextStatus = async () => {
    try {
      await dispatch(updateInvoiceStatus(data._id));
      refetchInvoices(); // Callback to parent to refetch invoices
    } catch (error) {
      console.error("Error updating invoice status:", error);
    }
  };

  return (
    <div className="border border-gray-200 rounded-md px-4 py-4">
      <button
        onClick={() => setViewMode(!viewMode)}
        className="rounded-md bg-gray-200 px-2.5 py-1.5 text-sm font-semibold text-gray-700 shadow-sm mr-2 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">
        {viewMode ? "Close Preview" : `View Invoice ${data.invoiceId}`}
      </button>

      <button
        onClick={handleLog}
        className="rounded-md bg-gray-200 px-2.5 py-1.5  text-sm font-semibold text-gray-700 shadow-sm mr-2 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">
        LOG
      </button>

      <button
        onClick={handleNextStatus}
        className="rounded-md bg-gray-200 px-2.5 py-1.5  text-sm font-semibold text-gray-700 shadow-sm mr-2 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400">
        Next Status
      </button>

      {viewMode && (
        <PDFViewer
          style={{
            width: "100%",
            height: "500px",
            marginBottom: "10px",
            marginTop: "10px",
          }}>
          <MyDocument data={data} />
        </PDFViewer>
      )}
    </div>
  );
};

const MyDocument = ({ data }) => {
  const clinic = data?.clinic || {};
  const clinician = data?.clinician || {};
  const client = data?.client || {};
  const services = data?.services || [];
  const address = client?.address || {};
  const parentInfo = data?.splitBilling?.parentInfo || {};

  const calculateSubtotal = (services) => {
    return services.reduce(
      (acc, service) => acc + service.cost * service.quantity,
      0
    );
  };

  const calculateTax = (services) => {
    return services.reduce((acc, service) => {
      if (service.isTaxable && service.taxRate && service.taxRate > 0) {
        return acc + service.cost * service.quantity * service.taxRate;
      }
      return acc;
    }, 0);
  };

  const subtotal = calculateSubtotal(services);
  const tax = calculateTax(services);
  const total = subtotal + tax;

  const formattedDate = new Date(data.updatedAt).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = new Date(data.updatedAt).toLocaleTimeString("en-US", {
    timeStyle: "long",
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header with logo */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <View style={{ alignItems: "flex-end" }}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>
              {clinic.name}
            </Text>
            <Text style={styles.text}>{clinic.address?.street || ""}</Text>
            <Text style={styles.text}>
              {`${clinic.address?.city || ""}, ${
                clinic.address?.province || ""
              }, ${clinic.address?.postalCode || ""}`}
            </Text>
          </View>
        </View>

        {/* Clinician and Invoice info */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            marginVertical: 10,
            paddingBottom: 20,
          }}>
          <View style={{ flexBasis: "70%" }}>
            <Text style={styles.text}>
              {clinician.firstName} {clinician.lastName}
            </Text>
            <Text style={styles.text}>{clinician.qualifications}</Text>
            <Text style={styles.text}>{clinician.jobTitle}</Text>
          </View>

          <View style={{ flexBasis: "30%", alignItems: "flex-end" }}>
            <Text style={styles.text}>Invoice: {data.invoiceId}</Text>
            <Text style={styles.text}>
              Service Date:{" "}
              {new Date(data.serviceDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </View>
        </View>

        {/* Client and Bill To */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}>
          {/* Client */}
          <View
            style={{
              marginBottom: 20,
              borderWidth: 1,
              flex: 1,
              marginRight: 10,
            }}>
            <View
              style={{
                borderBottom: 1,
                paddingHorizontal: 10,
                paddingVertical: 6,
              }}>
              <Text style={styles.text}>Client</Text>
            </View>
            <View style={{ paddingVertical: 8, paddingHorizontal: 10 }}>
              <Text style={styles.text}>{`${(client.firstName || "").trim()} ${(
                client.lastName || ""
              ).trim()}`}</Text>
              <Text style={styles.text}>
                {`${address.streetNumber || ""} ${address.streetName || ""}`}
              </Text>
              <Text style={styles.text}>{`${address.city || ""}, ${
                address.province || ""
              }, ${address.postalCode || ""}`}</Text>
            </View>
          </View>

          {/* Bill To */}
          <View
            style={{
              marginBottom: 20,
              borderWidth: 1,
              flex: 1,
              marginLeft: 10,
            }}>
            <View
              style={{
                borderBottom: 1,
                paddingHorizontal: 10,
                paddingVertical: 6,
              }}>
              <Text style={styles.text}>Bill To</Text>
            </View>
            <View style={{ paddingVertical: 8, paddingHorizontal: 10 }}>
              <Text style={styles.text}>{`${(parentInfo._id
                ? parentInfo.firstName
                : client.firstName || ""
              ).trim()} ${(parentInfo._id
                ? parentInfo.lastName
                : client.lastName || ""
              ).trim()}`}</Text>
              <Text style={styles.text}>
                {`${
                  parentInfo._id
                    ? parentInfo.address?.streetNumber || ""
                    : client.address?.streetNumber || ""
                } ${
                  parentInfo._id
                    ? parentInfo.address?.streetName || ""
                    : client.address?.streetName || ""
                }`}
              </Text>
              <Text style={styles.text}>{`${
                parentInfo._id
                  ? parentInfo.address?.city || ""
                  : client.address?.city || ""
              }, ${
                parentInfo._id
                  ? parentInfo.address?.province || ""
                  : client.address?.province || ""
              }, ${
                parentInfo._id
                  ? parentInfo.address?.postalCode || ""
                  : client.address?.postalCode || ""
              }`}</Text>
            </View>
          </View>
        </View>

        {/* Charges Table */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader, width: "50%" }}>
              <Text style={styles.tableCell}>Description</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "5%" }}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "30%" }}>
              <Text style={styles.tableCell}>Notes</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "15%" }}>
              <Text style={styles.tableCell}>Amount</Text>
            </View>
          </View>

          {services.map((service, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "50%" }}>
                <Text style={styles.tableCell}>{service.description}</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "5%",
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>{service.quantity}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "30%" }}>
                <Text style={styles.tableCell}>{service.notes}</Text>
              </View>
              <View
                style={{
                  ...styles.tableCol,
                  width: "15%",
                  alignItems: "flex-end",
                }}>
                <Text style={styles.tableCell}>
                  ${(service.cost * service.quantity).toFixed(2)}
                </Text>
              </View>
            </View>
          ))}

          {/* Subtotal row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderWidth: 0,
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Subtotal</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${subtotal.toFixed(2)}</Text>
            </View>
          </View>

          {/* Taxes row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "85%",
                borderWidth: 0,
                borderRightWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>
                Tax (
                {services.some((service) => service.isTaxable)
                  ? (
                      services.reduce((acc, service) => {
                        if (service.isTaxable) {
                          return acc + service.taxRate;
                        }
                        return acc;
                      }, 0) * 100
                    ).toFixed(0)
                  : 0}
                %)
              </Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>
                $
                {services.some((service) => service.isTaxable)
                  ? tax.toFixed(2)
                  : "0.00"}
              </Text>
            </View>
          </View>

          {/* Total row */}
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableCol,
                width: "70%",
                borderBottomWidth: 1,
                borderRightWidth: 0,
              }}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                borderBottomWidth: 1,
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                width: "15%",
                alignItems: "flex-end",
              }}>
              <Text style={styles.tableCell}>${total.toFixed(2)}</Text>
            </View>
          </View>
        </View>

        {/* Notes Section */}
        <View
          style={{
            marginTop: 20,
          }}>
          <Text style={styles.text}>Notes</Text>
          <View
            style={{
              marginTop: 5,
              padding: 5,
              borderWidth: 1,
              width: "100%",
              minHeight: 60,
            }}>
            <Text style={styles.text}>{data.notes ? data.notes : "N/A"}</Text>
          </View>
        </View>

        {/* Signature */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 50,
          }}>
          {" "}
          <Text style={styles.text}>
            Signature: ________________________________
          </Text>
          <Text style={styles.text}>
            Date: ________________________________
          </Text>
        </View>

        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <Text style={{ fontSize: 8 }}>
            [Version {data.__v + 1}. Last updated on {formattedDate}{" "}
            {formattedTime}]
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceGenerator;

// Styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    maxWidth: 180,
    maxHeight: 100,
    width: "auto",
    height: "auto",
  },

  borderSection: {
    marginBottom: 20,
    borderWidth: 1,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#D3D3D3",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});
