import { useEffect, useState } from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import calculateCounts from "./CalculateCounts"; // Adjust the path as necessary

const UserCards = ({ appointments = [], onFilterAppointments }) => {
    const [counts, setCounts] = useState({
        lastMonth: 0,
        lastWeek: 0,
        yesterday: 0,
        today: 0,
        tomorrow: 0,
        nextWeek: 0,
    });

    useEffect(() => {
        setCounts(calculateCounts(appointments));
    }, [appointments]);

    const now = new Date();
    const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const startOfYesterday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - 1));
    const startOfTomorrow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
    const startOfWeek = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() - now.getUTCDay()));
    const startOfLastWeek = new Date(startOfWeek);
    startOfLastWeek.setUTCDate(startOfLastWeek.getUTCDate() - 7);
    const startOfNextWeek = new Date(startOfWeek);
    startOfNextWeek.setUTCDate(startOfNextWeek.getUTCDate() + 7);
    const startOfMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1));
    const startOfLastMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1));
    const endOfLastMonth = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 0));

    const cards = [
        {
            name: "Last Month's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.lastMonth,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfLastMonth && new Date(appointment.start) <= endOfLastMonth
            ),
        },
        {
            name: "Last Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.lastWeek,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfLastWeek && new Date(appointment.start) < startOfWeek
            ),
        },
        {
            name: "Yesterday's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.yesterday,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfYesterday && new Date(appointment.start) < startOfToday
            ),
        },
        {
            name: "Today's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.today,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfToday && new Date(appointment.start) < startOfTomorrow
            ),
        },
        {
            name: "Tomorrow's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.tomorrow,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfTomorrow && new Date(appointment.start) < new Date(startOfTomorrow.getTime() + 24 * 60 * 60 * 1000)
            ),
        },
        {
            name: "Next Week's Appointments",
            href: "#",
            icon: CalendarDaysIcon,
            amount: counts.nextWeek,
            filter: (appointments) => appointments.filter(
                appointment => new Date(appointment.start) >= startOfNextWeek && new Date(appointment.start) < new Date(startOfNextWeek.getTime() + 7 * 24 * 60 * 60 * 1000)
            ),
        },
    ];

    return (
        <div className="mt-8">
            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {cards.map((card) => (
                        <div
                            key={card.name}
                            className="overflow-hidden rounded-lg bg-white shadow cursor-pointer"
                            onClick={() => onFilterAppointments(card.filter)}
                        >
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <card.icon
                                            className="h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            <dt className="truncate text-sm font-medium text-gray-500">
                                                {card.name}
                                            </dt>
                                            <dd>
                                                <div className="text-lg font-medium text-gray-900">
                                                    {card.amount}
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserCards;
