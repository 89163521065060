import { useState } from "react";
import WideSidePanel from "../../applicationUi/components/WideSidePanel";
import ClinicianDashboardComponent from "./clinician/ClinicianDashboardComponent";
import LockScreen from "../../auth/lockScreen";
import QuickAddModal from "../../modals/QuickAddModals";
import ClinicScheduleModal from "../schedulePage/ClinicScheduleModal";
import AdminDashboardComponent from "./admin/AdminDashboardComponent";
import OwnerDashboardComponent from "./owner/OwnerDashboardComponent";

function DashboardPage({ user }) {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isWideSidePanelOpen, setWideSidePanelOpen] = useState(false);
  const [isScreenLocked, setIsScreenLocked] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [rescheduleData, setRescheduleData] = useState(null); // State to hold reschedule data

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
    setWideSidePanelOpen(true);
  };

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setWideSidePanelOpen(false); // Close the WideSidePanel
  };

  const handleReschedule = (appointment) => {
    console.log("Rescheduling appointment:", appointment);
    setRescheduleData(appointment); // Set the appointment data to reschedule
    setWideSidePanelOpen(false); // Close the WideSidePanel
    setActiveModal("schedule"); // Open the ClinicScheduleModal
    console.log("ClinicScheduleModal open state set to true");
  };

  const handleQuickAddClientClick = () => {
    setActiveModal("client");
  };

  const handleQuickAddApptClick = () => {
    setActiveModal("appointment");
  };

  const handleScheduleModalClick = () => {
    setActiveModal("schedule");
  };

  const handleCloseQuickAddModal = () => {
    setActiveModal(null);
  };

const renderDashboard = () => {
  const roles = (user?.role || []).map(role => role.toLowerCase()); // Convert all roles to lowercase
  console.log("ROLES ", roles);

  // Collect all the components to render
  const components = [];

  if (roles.includes('clinician')) {
    components.push(
      <ClinicianDashboardComponent
        key="clinician-dashboard"
        handleAppointmentClick={handleAppointmentClick}
        handleClientClick={handleClientClick}
        handleReschedule={handleReschedule}
        clinician={user}
      />
    );
  }

  if (roles.includes('admin')) {
    components.push(
      <AdminDashboardComponent
        key="admin-dashboard"
        handleAppointmentClick={handleAppointmentClick}
        handleClientClick={handleClientClick}
        handleReschedule={handleReschedule}
        clinician={user}
      />
    );
  }

  if (roles.includes('owner')) {
    components.push(
      <OwnerDashboardComponent
        key="owner-dashboard"
        handleAppointmentClick={handleAppointmentClick}
        handleClientClick={handleClientClick}
        handleReschedule={handleReschedule}
        clinician={user}
      />
    );
  }

  // If no roles match, display a default message
  if (components.length === 0) {
    return <div>Role not recognized</div>;
  }

  return <div>{components}</div>;
};


  return (
    <>
      {isScreenLocked && <LockScreen />}
      {/* <ClinicianDashboardComponent
        handleAppointmentClick={handleAppointmentClick}
        handleClientClick={handleClientClick}
        handleReschedule={handleReschedule} // Pass handleReschedule to ClinicianDashboardComponent
        clinician={user}
      /> */}
      {renderDashboard()}
      {selectedAppointment && (
        <WideSidePanel
          title={"Appointment Summary"}
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          // client={selectedAppointment?.client}
          appointment={selectedAppointment}
          onAppointmentClick={handleAppointmentClick}
          onReschedule={handleReschedule}
        />
      )}
      <QuickAddModal
        activeModal={activeModal}
        onClose={handleCloseQuickAddModal}
      />
      {activeModal === "schedule" && (
        <ClinicScheduleModal
          setModalOpen={(state) => {
            setActiveModal(state ? "schedule" : null);
            console.log("ClinicScheduleModal open state set to:", state);
          }}
          selectedClient={rescheduleData?.client}
          selectedService={rescheduleData?.service}
          selectedProduct={rescheduleData?.product}
          selectedClinician={rescheduleData?.clinician}
          rescheduleData={rescheduleData} // Pass reschedule data to ClinicScheduleModal
        />
      )}
    </>
  );
}

export default DashboardPage;
