import InvoiceGenerator from "./InvoiceGenerator";
import React, { useState } from "react";
import EditInvoiceForm from "./EditInvoiceForm";

function InvoiceList({ invoices = [], refetchInvoices }) {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);

  console.log(invoices);

  const onInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setShowInvoiceGenerator(true);
    setEditingInvoice(null);
    console.log("this invoice has been clicked", invoice);
  };

  const formatPrice = (price) => {
    if (price === undefined || price === null) {
      return "$0.00";
    }
    const numPrice = Number(price);
    return `$${numPrice.toFixed(2)}`;
  };

  const handleEditInvoice = (invoice) => {
    setEditingInvoice(invoice);
    setShowInvoiceGenerator(false);
    setSelectedInvoice(null);
  };

  return (
    <>
      {showInvoiceGenerator && selectedInvoice && !editingInvoice && (
        <div className="mb-4">
          <InvoiceGenerator
            data={selectedInvoice}
            refetchInvoices={refetchInvoices}
          />
        </div>
      )}

      {!showInvoiceGenerator && editingInvoice && (
        <EditInvoiceForm
          invoice={editingInvoice}
          closeForm={() => setEditingInvoice(null)}
          refetchInvoices={refetchInvoices}
        />
      )}

      <div className="mt-2">
        <div className="mx-auto max-w-7xl px-4 ">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Invoice ID
                  </th>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Client
                  </th>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Bill To
                  </th>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Service Date
                  </th>
                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Service
                  </th>

                  <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {invoices.map((invoice, index) => (
                  <tr
                    key={`${invoice._id}-${index}`}
                    className="bg-white hover:bg-gray-100 cursor-pointer">
                    <td className="px-2 py-4 text-sm text-gray-900">
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleEditInvoice(invoice)}>
                        Edit
                      </button>
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {invoice.invoiceId}
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {invoice.client.firstName} {invoice.client.lastName}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {invoice?.splitBilling?.isChild
                        ? `${invoice.client?.firstName || ""} ${
                            invoice.client?.lastName || ""
                          }`
                        : invoice?.splitBilling?.isParent
                        ? `${
                            invoice.splitBilling.parentInfo?.firstName || ""
                          } ${invoice.splitBilling.parentInfo?.lastName || ""}`
                        : `${invoice.client?.firstName || ""} ${
                            invoice.client?.lastName || ""
                          }`}
                    </td>

                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {new Date(invoice.serviceDate).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {invoice.services.map((service) => (
                        <div key={service._id}>
                          {service.description}{" "}
                          <p> {formatPrice(invoice.amount)}</p>
                        </div>
                      ))}
                    </td>
                    <td
                      className="px-2 py-4 text-sm text-gray-900"
                      onClick={() => onInvoiceClick(invoice)}>
                      {invoice.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceList;
