import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateClient } from "../../store/thunks/clientsThunk";

const canadianProvinces = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" },
];

export default function EditClientModal({ client, onClose, onCancel }) {
  console.log("Client to edit (received in EditClientModal):", client); // Log the entire client object received in EditClientModal
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onCancel();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const clientData = {
      firstName: formData.get("clientFirstName"),
      lastName: formData.get("clientLastName"),
      dateOfBirth: formData.get("clientDateOfBirth"),
      type: client.type,
      address: {
        aptUnit: formData.get("clientAptUnit"),
        streetNumber: formData.get("clientStreetNumber"),
        streetName: formData.get("clientStreetName"),
        city: formData.get("clientCity"),
        province: formData.get("clientProvince"),
        postalCode: formData.get("clientPostalCode"),
      },
      healthCardNumber: formData.get("clientHealthCardNumber"),
      gender: formData.get("clientGender"),
      phoneNumber: formData.get("clientPhoneNumber"),
    };

    // Validate required fields
    if (
      !clientData.firstName ||
      !clientData.lastName ||
      !clientData.dateOfBirth
    ) {
      alert("Client's first name, last name, and date of birth are required.");
      return;
    }

    dispatch(updateClient({ id: client._id, clientData }));
    onClose();
  };

  const inputFields = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "phoneNumber",
    "aptUnit",
    "streetNumber",
    "streetName",
    "city",
    "province",
    "postalCode",
    "healthCardNumber",
    "gender",
  ];

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={onCancel}
      />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              Edit Client
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Use this form to edit the client information.
            </p>
          </div>

          {/* Client Information */}
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {client.type === "parent"
                ? "Parent/Guardian Information"
                : "Client Information"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Update the information of the{" "}
              {client.type === "parent" ? "parent/guardian" : "client"}.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
              {inputFields.map((field) => (
                <div key={`client-${field}`} className="sm:col-span-1">
                  <label
                    htmlFor={`client${
                      field.charAt(0).toUpperCase() + field.slice(1)
                    }`}
                    className="block text-sm font-medium leading-6 text-gray-900">
                    {field
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </label>
                  <div className="mt-2">
                    {field === "province" || field === "gender" ? (
                      <select
                        name={`client${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        id={`client${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        defaultValue={
                          client.address?.[field] || client[field] || ""
                        }
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        {field === "province" ? (
                          canadianProvinces.map((province) => (
                            <option key={province.value} value={province.value}>
                              {province.label}
                            </option>
                          ))
                        ) : (
                          <>
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="transgender">Transgender</option>
                            <option value="genderqueer">Genderqueer</option>
                            <option value="non-binary">Non-binary</option>
                            <option value="agender">Agender</option>
                            <option value="bigender">Bigender</option>
                            <option value="other">Other</option>
                          </>
                        )}
                      </select>
                    ) : (
                      <input
                        type={field === "dateOfBirth" ? "date" : "text"}
                        name={`client${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        id={`client${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        defaultValue={
                          field === "dateOfBirth"
                            ? client.dateOfBirth?.substring(0, 10) || ""
                            : client.address?.[field] || client[field] || ""
                        }
                        autoComplete={field
                          .replace(/([A-Z])/g, " $1")
                          .toLowerCase()}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Save and Cancel Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onCancel}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
