// src/store/thunks/todoThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchTodos = createAsyncThunk(
  "todos/fetchTodos",
  async (_, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(`${baseURL}/api/todos`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
