import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentsByDateRange,
  fetchAppointmentsByClinicianAndDateRange,
} from "../../store/thunks/appointmentsThunk";
import {
  fetchPayrollsByPayPeriod,
  fetchPayrollsByClinicianAndPayPeriod,
  fetchPayrollsByClinicianStatusAndPayPeriod,
} from "../../store/thunks/payrollThunk";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import HRPayrollPage from "./HRPayrollPage";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function HRPayrollOverviewPage() {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []); // Default to an empty array if undefined

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [ishrpayrollpage, setishrpayrollpage] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [payperiodAppointments, setPayperiodAppointments] = useState([]);
  const [completedPayrolls, setCompletedPayrolls] = useState([]);
  const [clinicianData, setClinicianData] = useState([]);

  useEffect(() => {
    dispatch(fetchClinicians());
  }, [dispatch]);

  const findClosestPayPeriod = () => {
    const currentDate = new Date();
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(`${startDateString} 2024`);
      const endDate = new Date(`${endDateString} 2024`);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    return closestPeriod;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(findClosestPayPeriod());

  const fetchClinicianSpecificData = () => {
    if (selectedPeriod) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date} 2024`));
      setStartDate(start);
      setEndDate(end);

      if (clinicians && clinicians.length > 0) {
        const promises = clinicians.map((clinician) => {
          const clinicianId = clinician._id;

          // Fetch Pending Appointments
          const appointmentsPromise = dispatch(
            fetchAppointmentsByClinicianAndDateRange({
              clinicianId,
              startDate: start,
              endDate: end,
            })
          ).then((result) => ({
            clinicianId,
            pendingAppointments: result.payload.length,
          }));

          // Fetch Completed Payrolls
          const payrollsPromise = dispatch(
            fetchPayrollsByClinicianAndPayPeriod({
              clinicianId,
              payPeriod: selectedPeriod.payPeriodNumber,
            })
          ).then((result) => ({
            clinicianId,
            completedAppointments: result.payload.length,
          }));

          // Fetch Carryover Payrolls
          const carryoverPayrollsPromise = dispatch(
            fetchPayrollsByClinicianStatusAndPayPeriod({
              clinicianId,
              payPeriod: selectedPeriod.payPeriodNumber,
              status: ["Carryover"],
            })
          ).then((result) => {
            console.log(
              `Carryover Payrolls for clinician ${clinicianId}:`,
              result.payload
            );
            return {
              clinicianId,
              carryoverPayrolls: result.payload,
            };
          });

          return Promise.all([
            appointmentsPromise,
            payrollsPromise,
            carryoverPayrollsPromise,
          ])
            .then(([appointmentsData, payrollsData, carryoverPayrollsData]) => {
              console.log(
                `Carryover Payrolls for clinician ${clinicianId} stored in carryoverPayrollsData:`,
                carryoverPayrollsData.carryoverPayrolls
              );
              return {
                clinicianId,
                email: clinician.email,
                pendingAppointments: appointmentsData.pendingAppointments,
                completedAppointments: payrollsData.completedAppointments,
                carryoverPayrolls: carryoverPayrollsData.carryoverPayrolls,
              };
            })
            .catch((error) => {
              console.error(
                `Error fetching data for clinician ${clinicianId}:`,
                error
              );
              return {
                clinicianId,
                email: clinician.email,
                pendingAppointments: 0,
                completedAppointments: 0,
                carryoverPayrolls: 0,
              };
            });
        });

        Promise.all(promises)
          .then((results) => {
            const updatedData = clinicians.map((clinician, index) => {
              return {
                ...clinician,
                clinicianId: clinician._id,
                completedAppointments:
                  results[index].completedAppointments || 0,
                pendingAppointments: results[index].pendingAppointments || 0,
                carryoverPayrolls: results[index].carryoverPayrolls || 0,
              };
            });

            console.log(
              "All carryover payrolls stored in updatedData:",
              updatedData.map((clinician) => ({
                clinicianId: clinician.clinicianId,
                carryoverPayrolls: clinician.carryoverPayrolls,
              }))
            );

            setClinicianData(updatedData);
            console.log("Clinician data with full info:", updatedData);
          })
          .catch((error) => {
            console.error("Error fetching clinician data:", error);
          });
      } else {
        console.error("No clinicians found in the store");
      }
    }
  };

  const fetchOverviewData = () => {
    if (selectedPeriod) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date} 2024`));
      setStartDate(start);
      setEndDate(end);

      dispatch(
        fetchAppointmentsByDateRange({
          startDate: start,
          endDate: end,
        })
      )
        .then((result) => {
          setPayperiodAppointments(result.payload);
          console.log("PENDING APPOINTMENTS:", result.payload);
        })
        .catch((error) => {
          console.error("Dispatch error:", error);
        });

      dispatch(
        fetchPayrollsByPayPeriod({
          payPeriod: selectedPeriod.payPeriodNumber, // Assuming this is the correct property for pay period
        })
      )
        .then((result) => {
          if (Array.isArray(result.payload)) {
            setCompletedPayrolls(result.payload);
          } else {
            setCompletedPayrolls([]);
            console.error("Expected an array but received:", result.payload);
          }
          console.log("RDY FOR PAYROLL APPTS", result.payload);
        })
        .catch((error) => {
          console.error("Fetch Payrolls Error:", error);
        });
    }
  };

  useEffect(() => {
    fetchClinicianSpecificData();
    fetchOverviewData();
  }, [dispatch, selectedPeriod]);

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
  };

  const calculatePendingPay = () => {
    return payperiodAppointments.reduce((total, appointment) => {
      const price = appointment.service?.price || 0;
      return total + price * 0.45;
    }, 0);
  };

  const calculateEstimatedPay = () => {
    if (!Array.isArray(completedPayrolls)) {
      return "0.00";
    }
    return completedPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const calculateAppointmentsCompleted = () => {
    return completedPayrolls.length;
  };

  const calculatePendingAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateCarryoverAppointments = () => {
    if (!Array.isArray(clinicianData)) {
      return "0";
    }

    // Sum the length of carryoverPayrolls arrays across all clinicians
    const totalCarryoverAppointments = clinicianData.reduce(
      (sum, clinician) => {
        if (Array.isArray(clinician.carryoverPayrolls)) {
          return sum + clinician.carryoverPayrolls.length;
        }
        return sum;
      },
      0
    );

    return totalCarryoverAppointments.toString();
  };

  const calculateCarryoverPay = () => {
    if (!Array.isArray(clinicianData)) {
      return "0.00";
    }

    // Sum carryover pay across all clinicians
    const totalCarryoverPay = clinicianData.reduce((sum, clinician) => {
      if (Array.isArray(clinician.carryoverPayrolls)) {
        return (
          sum +
          clinician.carryoverPayrolls.reduce(
            (subSum, payroll) => subSum + (payroll.payrollAmount || 0),
            0
          )
        );
      }
      return sum;
    }, 0);

    return totalCarryoverPay.toFixed(2);
  };

  // const result = calculateCarryoverPay(clinicianData);
  // console.log(result);

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  // Calculate stats
  const stats = [
    {
      name: "Pay Period",
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateAppointmentsCompleted()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay().toFixed(2)}`,
      change: "",
      changeType: "neutral",
      extra: "(Default 45%)",
    },
    {
      name: "Total Complete Pay",
      value: `$${calculateEstimatedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const handleClinicianClick = (clinicianId) => {
    setSelectedClinician(clinicianId);
    setishrpayrollpage(true);
    console.log("Clinician Click" + clinicianId);
  };

  return (
    <>
      <div className="py-4 pb-20">
        <PayrollStats stats={stats} />
        {/* Pay period selector and toggles */}
        <div className="flex space-x-4 mb-4 justify-around items-center">
          <div className="flex flex-col items-center space-y-2">
            <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
              <div className="flex flex-col items-center">
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                  Select Payroll Period
                </Listbox.Label>
                <div className="relative mt-2 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {payrollDates.map((period) => (
                      <Listbox.Option
                        key={period.payPeriodNumber}
                        value={period}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-9 ${
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`}>
                              {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </div>
            </Listbox>
          </div>

          {/* <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Soap Notes Complete
            </label>
            <Switch
              checked={soapNotesComplete}
              onChange={(value) => {
                setSoapNotesComplete(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                soapNotesComplete ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Soap Notes Complete</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  soapNotesComplete ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Invoice Paid
            </label>
            <Switch
              checked={invoicePaid}
              onChange={(value) => {
                setInvoicePaid(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                invoicePaid ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Invoice Paid</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  invoicePaid ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Eligible for payroll
            </label>
            <Switch
              checked={eligibleForPayroll}
              onChange={(value) => {
                setEligibleForPayroll(value);
                setSoapNotesComplete(value);
                setInvoicePaid(value);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                eligibleForPayroll ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Eligible for Payroll</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  eligibleForPayroll ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div> */}
          <div>
            <button
              type="button"
              onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Payroll Schedule
            </button>
          </div>
        </div>

        {/* Clinician grid tiles */}
        <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
            {clinicianData.map((data) => (
              <li
                key={data.clinicianId}
                className="overflow-hidden rounded-xl border border-gray-200"
                onClick={() => handleClinicianClick(data.clinicianId)}>
                <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-4">
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    {data.firstName} {data.lastName}
                  </div>
                </div>
                <dl className="-my-3 divide-y divide-gray-100 px-4 py-4 text-sm leading-6">
                  {/* <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Email</dt>
                    <dd className="text-gray-700">{data.email}</dd>
                  </div> */}
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Pending Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.pendingAppointments}
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Completed Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.completedAppointments}
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Carryover Appts</dt>
                    <dd className="font-medium text-gray-900">
                      {data.carryoverPayrolls.length}
                    </dd>
                  </div>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}

      {ishrpayrollpage && selectedClinician && (
        <HRPayrollPage
          clinicianId={selectedClinician}
          selectedPeriod={selectedPeriod}
          isOpen={ishrpayrollpage}
          onClose={() => setishrpayrollpage(false)}
        />
      )}

      {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={() => setIsPayrollSlideOver(false)}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          isHr={true}
          onUpdateSuccess={fetchOverviewData}
        />
      )}
    </>
  );
}
