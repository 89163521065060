import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const searchApi = createAsyncThunk(
    "search/fetchResults",
    async (query, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(`${baseURL}/api/search`, {
                ...config,
                params: { query }, // Correct way to pass the query
            });

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);
