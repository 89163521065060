import React, { useState, useEffect } from "react";
import ClinicianInfo from "./clinicianInfo";
import QuickAddAppointmentModal from "../../../modals/QuickAddAppointmentModal";
import AppointmentConfirmationModal from "../../../modals/AppointmentConfirmationModal";
import CalendarEvent from "./CalendarEvent";
import SOAPFormModal from "../../../applicationUi/components/SOAPForm";
import ContextMenu from "../ContextMenu";
import { useDispatch } from "react-redux";
import {
  fetchAppointmentsByClinicianId,
  saveAppointment,
  updateAppointmentStatus,
} from "../../../store/thunks/appointmentsThunk";

const ClinicianSchedule = ({
  clinician,
  appointments,
  selectedClient,
  selectedService,
  selectedProduct,
  selectedClinician,
  currentDate,
  handleAppointmentClick, // Receive the handleAppointmentClick function as a prop
  showWorkingHours, // New prop for working hours toggle
  onReschedule, // Add this prop to handle reschedule
  rescheduleData,
}) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [selectedClinicianInfo, setSelectedClinicianInfo] = useState(clinician);
  const [currentStep, setCurrentStep] = useState("add"); // 'add' or 'confirm'
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    appointment: null,
  });
  const [showSOAPForm, setShowSOAPForm] = useState(false);
  const [soapFormAppointment, setSoapFormAppointment] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [currentTimePosition, setCurrentTimePosition] = useState(null);

  const startHour = showWorkingHours ? 9 : 0;
  const endHour = showWorkingHours ? 18 : 24;
  const hoursToShow = endHour - startHour;

  const statusOptions = [
    "Booked",
    "Changed",
    "Cancelled",
    "Tentative",
    "Confirmed",
    "Treated",
    "Completed",
    "Invoiced",
    "Paid",
    "Soap Notes Complete",
    "Comments",
    "Paid to Clinician",
  ];

  const [selectedStatus, setSelectedStatus] = useState("");
  const [showStatusSelector, setShowStatusSelector] = useState(false);

  const statusOrder = [
    "Booked",
    "Tentative",
    "Confirmed",
    "Changed",
    "Cancelled",
    "Treated",
    "Soap Notes Complete",
    "Soap Notes Approved",
    "Invoiced",
    "Paid",
    "Paid to Clinician",
    "Completed",
  ];

  const [passedRescheduleData, setPassedRescheduleData] = useState({});

  useEffect(() => {
    if (rescheduleData && rescheduleData !== passedRescheduleData) {
      setPassedRescheduleData(rescheduleData);
      console.log("reschedule data has been set", rescheduleData);
      console.log("set2", passedRescheduleData);
    } else {
      console.log("No data has been passed in to reschedule");
    }
  }, [rescheduleData]);

  // Refetch appointments
  const refetchAppointments = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  // Get appts matching date
  useEffect(() => {
    const clinicianAppointments = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.start);
      return (
        appointment.clinician?._id === clinician._id &&
        appointmentDate.toDateString() === currentDate.toDateString()
      );
    });
    setFilteredAppointments(clinicianAppointments);
  }, [appointments, clinician, currentDate]);

  // Render appts as calendar events
  const renderAppointments = () => {
    const appointmentsWithColumns = [];
    const overlappingAppointments = [];

    filteredAppointments.forEach((appointment) => {
      const start = new Date(appointment.start).getTime();
      const end = new Date(appointment.end).getTime();
      let placed = false;

      for (let i = 0; i < overlappingAppointments.length; i++) {
        const overlap = overlappingAppointments[i];
        if (
          overlap.every(
            (a) =>
              start >= new Date(a.end).getTime() ||
              end <= new Date(a.start).getTime()
          )
        ) {
          overlap.push(appointment);
          appointmentsWithColumns.push({
            ...appointment,
            column: i,
            maxColumns: overlappingAppointments.length,
          });
          placed = true;
          break;
        }
      }

      if (!placed) {
        overlappingAppointments.push([appointment]);
        appointmentsWithColumns.push({
          ...appointment,
          column: overlappingAppointments.length - 1,
          maxColumns: overlappingAppointments.length,
        });
      }
    });

    const maxColumns = Math.max(
      ...appointmentsWithColumns.map((appointment) => appointment.maxColumns)
    );

    appointmentsWithColumns.forEach((appointment) => {
      appointment.maxColumns = maxColumns;
    });

    return appointmentsWithColumns.map((appointment) => (
      <CalendarEvent
        key={appointment._id}
        appointment={appointment}
        onClick={() => handleAppointmentClick(appointment)}
        onRightClick={(e) => handleRightClick(e, appointment)} // Pass the right-click handler
        column={appointment.column}
        maxColumns={appointment.maxColumns}
        startHour={startHour} // Pass startHour to adjust the grid row calculation
      />
    ));
  };

  // For the red line
  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (now.toDateString() === currentDate.toDateString()) {
        const minutesSinceStart =
          (now.getHours() - startHour) * 60 + now.getMinutes();
        const position = (minutesSinceStart / (hoursToShow * 60)) * 100;
        setCurrentTimePosition(position);
      } else {
        setCurrentTimePosition(null);
      }
    };

    // Initial position update
    updateCurrentTimePosition();

    // Set interval to update position every minute
    const interval = setInterval(updateCurrentTimePosition, 60000);

    return () => clearInterval(interval);
  }, [currentDate, startHour, hoursToShow]);

  const handleCreateSOAPNote = () => {
    console.log("Opening SOAP form");
    setSoapFormAppointment(contextMenu.appointment);
    setShowSOAPForm(true);
    handleContextMenuClose(); // Close context menu after option is clicked
  };

  const handleMarkAsTreated = () => {
    const updatedAppointment = {
      ...contextMenu.appointment,
      status: ["Treated"],
      history: [
        ...contextMenu.appointment.history,
        {
          status: "Treated",
          timestamp: new Date(),
          comments: "Marked as treated",
          user: contextMenu.appointment.clinician, // assuming the clinician is the user marking it as treated
        },
      ],
    };

    dispatch(saveAppointment(updatedAppointment))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          console.log("Appointment marked as treated:", response.payload);
          // Refetch appointments
          refetchAppointments(contextMenu.appointment.clinician._id);
        }
      })
      .catch((error) => {
        console.error("Failed to mark appointment as treated:", error);
      });

    handleContextMenuClose(); // Close context menu after action
  };

  const handleMarkAsConfirmed = () => {
    console.log("appt confirmed", contextMenu.appointment);
  };

  const getNextStatus = (currentStatus) => {
    const currentIndex = statusOrder.indexOf(currentStatus);
    return currentIndex >= 0 && currentIndex < statusOrder.length - 1
      ? statusOrder[currentIndex + 1]
      : currentStatus;
  };

  const handleNextStatus = () => {
    const statusOrder = [
      "Booked",
      "Tentative",
      "Confirmed",
      "Changed",
      "Cancelled",
      "Treated",
      "Invoiced",
      "Soap Notes Complete",
      "Soap Notes Approved",
      "Paid",
      "Paid to Clinician",
      "Completed",
    ];

    const currentStatus = contextMenu.appointment.status[0];
    const nextStatusIndex = statusOrder.indexOf(currentStatus) + 1;
    const nextStatus = statusOrder[nextStatusIndex];

    if (nextStatus) {
      dispatch(
        updateAppointmentStatus({
          appointmentId: contextMenu.appointment._id,
          status: nextStatus,
          comments: `Status changed to ${nextStatus}`,
          userId: contextMenu.appointment.clinician._id, // assuming the clinician is the user marking the status change
        })
      )
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            console.log(
              `Appointment status changed to ${nextStatus}:`,
              response.payload
            );
            // Refetch appointments
            refetchAppointments(contextMenu.appointment.clinician._id);
          }
        })
        .catch((error) => {
          console.error(
            `Failed to change appointment status to ${nextStatus}:`,
            error
          );
        });

      handleContextMenuClose(); // Close context menu after action
    } else {
      console.log("No further status available");
    }
  };

  const handleStatusChange = () => {
    if (!contextMenu.appointment) {
      console.error("No appointment selected.");
      return;
    }

    const updatedAppointment = {
      ...contextMenu.appointment,
      status: [selectedStatus],
      history: [
        ...(contextMenu.appointment.history || []),
        {
          status: selectedStatus,
          timestamp: new Date(),
          comments: `Marked as ${selectedStatus}`,
          user: contextMenu.appointment.clinician, // assuming the clinician is the user marking it
        },
      ],
    };

    dispatch(saveAppointment(updatedAppointment))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          console.log(
            `Appointment marked as ${selectedStatus}:`,
            response.payload
          );
          // Refetch appointments
          refetchAppointments(contextMenu.appointment.clinician._id);
        }
      })
      .catch((error) => {
        console.error(
          `Failed to mark appointment as ${selectedStatus}:`,
          error
        );
      });

    setShowStatusSelector(false); // Close status selector
    handleContextMenuClose(); // Close context menu after action
  };

  useEffect(() => {
    if (showSOAPForm) {
      console.log("SOAP Form is open");
      handleContextMenuClose(); // Close context menu after confirming SOAP form is open
    }
  }, [showSOAPForm]);

  // Show right click menu
  const handleRightClick = (e, appointment) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      appointment,
    });
    setShowStatusSelector(false);
  };

  // Create appointment
  const handleGridRightClick = (e) => {
    e.preventDefault();
    const clickedGridItem = e.target.closest("li");
    if (clickedGridItem) {
      const row = parseInt(clickedGridItem.dataset.row, 10);
      if (row >= 2 && row <= hoursToShow * 12) {
        const hour = startHour + Math.floor((row - 2) / 12);
        const minute = ((row - 2) % 12) * 5;
        const timeSelected = `${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;

        setModalContent({
          date: new Date(currentDate),
          time: timeSelected,
          clinicianFname: clinician.firstName,
          clinicianLname: clinician.lastName,
        });

        setSelectedClinicianInfo(clinician);
        setShowModal(true);
        // setCurrentStep("add");
      }
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu({ visible: false, x: 0, y: 0, appointment: null });
    setShowStatusSelector(false); // Ensure the selector is hidden when the context menu is closed
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowDetailsModal(false);
    setShowSOAPForm(false); // Close SOAP form as well
  };

  const handleSave = (appointmentData) => {
    setAppointmentDetails(appointmentData);
    setShowModal(false);
    setShowDetailsModal(true);
    // setCurrentStep("confirm");
  };

  const handleGoBack = () => {
    setShowDetailsModal(false);
    setShowModal(true);
    setCurrentStep("add");
  };

  const handleLogObject = () => {
    console.log(contextMenu.appointment);
    handleContextMenuClose(); // Close context menu after option is clicked
  };

  const handleMouseEnter = (rowIndex) => {
    setHoveredRow(rowIndex);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const formatTime = (rowIndex) => {
    const totalMinutes = rowIndex * 5 - 5;
    const hour = startHour + Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;

    return new Date(
      `1970-01-01T${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:00`
    ).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <>
      <ClinicianInfo clinician={clinician} />
      <div className="flex w-full flex-auto">
        <div className="w-14 flex-none bg-white ring-1 ring-gray-100 relative">
          {currentTimePosition !== null && (
            <div
              className="absolute left-0 text-left pl-2 text-xs leading-5 text-red-500 current-time-label"
              style={{ top: `${currentTimePosition}%` }}>
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </div>
          )}
        </div>

        <div className="grid flex-auto grid-cols-1 grid-rows-1 relative">
          <div
            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
            style={{
              gridTemplateRows: `repeat(${
                hoursToShow * 2
              }, minmax(3.5rem, 1fr))`,
            }}>
            <div className="row-end-1 h-7"></div>
            {[...Array(hoursToShow).keys()].map((hour) => (
              <React.Fragment key={hour}>
                <div>
                  <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    {(hour + startHour) % 12 === 0
                      ? hour + startHour === 0
                        ? "12AM"
                        : "12PM"
                      : `${(hour + startHour) % 12}${
                          hour + startHour < 12 ? "AM" : "PM"
                        }`}
                  </div>
                </div>
                <div />
              </React.Fragment>
            ))}
          </div>
          <ol
            className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 relative"
            style={{
              gridTemplateRows: `1.75rem repeat(${
                hoursToShow * 12
              }, minmax(0, 1fr)) auto`,
            }}
            onContextMenu={handleGridRightClick}>
            {Array.from({ length: hoursToShow * 12 }, (_, rowIndex) =>
              Array.from({ length: 1 }, (_, colIndex) => (
                <li
                  key={`${rowIndex}-${colIndex}`}
                  data-row={rowIndex + 1}
                  data-col={rowIndex + 1}
                  onMouseEnter={() => handleMouseEnter(rowIndex)}
                  onMouseLeave={handleMouseLeave}
                  className={`${
                    hoveredRow === rowIndex ? "bg-gray-200 font-bold" : ""
                  }`}>
                  {hoveredRow === rowIndex && (
                    <div className="absolute left-0 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      {formatTime(rowIndex)}
                    </div>
                  )}
                </li>
              ))
            )}
            {renderAppointments()}
            {currentTimePosition !== null && (
              <div
                className="absolute w-full border-t border-red-500"
                style={{ top: `${currentTimePosition}%` }}
              />
            )}
          </ol>
        </div>
      </div>

      {contextMenu.visible && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          options={[
            {
              label: "Testing... log the appointment",
              onClick: handleLogObject,
            },
            ...(contextMenu.appointment?.history.some(
              (h) => h.status === "Treated"
            )
              ? [{ label: "SOAP Note", onClick: handleCreateSOAPNote }]
              : []),
            { label: "Mark as Confirmed", onClick: handleMarkAsConfirmed },
            { label: "Mark as Treated", onClick: handleMarkAsTreated },
            { label: "Next status", onClick: handleNextStatus }, // Added "Next status" option
          ]}
          onClose={handleContextMenuClose}
          showStatusSelector={showStatusSelector}
          statusOptions={statusOptions}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          handleStatusChange={handleStatusChange}
        />
      )}

      {showStatusSelector && (
        <div
          className="absolute z-50 bg-white border rounded shadow-lg p-2"
          style={{ top: contextMenu.y, left: contextMenu.x }}>
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className="p-2 border rounded w-full">
            <option value="" disabled>
              Select status
            </option>
            {statusOptions.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
          <div className="flex mt-2">
            <button
              onClick={handleStatusChange}
              className="p-2 bg-blue-500 text-white rounded mr-2">
              Confirm
            </button>
            <button
              onClick={() => setShowStatusSelector(false)}
              className="p-2 bg-gray-300 text-black rounded">
              Cancel
            </button>
          </div>
        </div>
      )}
      {/* {currentStep === "add" && ( */}
      <QuickAddAppointmentModal
        isOpen={showModal}
        onClose={handleModalClose}
        initialDate={modalContent?.date}
        initialTime={modalContent?.time}
        initialClinicianFname={modalContent?.clinicianFname}
        initialClinicianLname={modalContent?.clinicianLname}
        rescheduleData={passedRescheduleData}
        onSave={handleSave}
        selectedClinician={selectedClinicianInfo} // Pass the selected clinician
      />
      {/* )} */}
      {/* {currentStep === "confirm" && (
        <AppointmentConfirmationModal
          isOpen={showDetailsModal}
          onClose={handleModalClose}
          appointmentDetails={appointmentDetails}
          onGoBack={handleGoBack}
          onConfirm={handleAppointmentCreate}
        />
      )} */}
      {showSOAPForm && soapFormAppointment && (
        <SOAPFormModal
          onClose={handleModalClose}
          appointment={soapFormAppointment}
        />
      )}
    </>
  );
};

export default ClinicianSchedule;
