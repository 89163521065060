import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients, deleteClient } from "../../store/thunks/clientsThunk";
import { fetchInvoicesByClientId } from "../../store/thunks/invoiceThunk";
import { fetchSoapNotesByClientId } from "../../store/thunks/appointmentsThunk"; // Updated thunk import
import EditClientModal from "./EditClientModal";
import ConfirmDeleteDialog from "../../dialogs/ConfirmDeleteDialog";
import ClientOverviewModal from "./ClientOverviewModal"; // New modal component
import Table from "../../applicationUi/components/Table";
import { format, differenceInYears } from "date-fns";

export default function ClientsPage() {
  const dispatch = useDispatch();
  // const {
  //   clients,
  //   pagination,
  //   status: clientStatus,
  //   error: clientError,
  // } = useSelector((state) => state.clients);
  const {
    clients = [], // Ensure clients is an array
    pagination = { currentPage: 1, pageSize: 10 }, // Default pagination values
    status: clientStatus,
    error: clientError,
  } = useSelector((state) => state.clients);

  const [selectedClient, setSelectedClient] = useState(null); // Track selected client for overview modal
  const [isClientOverviewModalOpen, setIsClientOverviewModalOpen] =
    useState(false); // New modal state
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState(false);
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  useEffect(() => {
    dispatch(
      fetchClients({
        page: pagination.currentPage,
        limit: pagination.pageSize,
      })
    )
      .unwrap()
      .catch((error) => {
        console.error("Failed to fetch clients:", error);
      });
  }, [dispatch, pagination.currentPage, pagination.pageSize]);

  // useEffect(() => {
  //   dispatch(
  //     fetchClients({
  //       page: pagination.currentPage,
  //       limit: pagination.pageSize,
  //     })
  //   );
  // }, [dispatch, pagination.currentPage, pagination.pageSize]);

  const fetchInvoicesDataByClientId = async (clientId) => {
    try {
      const response = await dispatch(
        fetchInvoicesByClientId(clientId)
      ).unwrap();
      return response;
    } catch (error) {
      console.error("Failed to fetch invoices by client ID:", error);
      return [];
    }
  };

  const fetchSoapNotesDataByClientId = async (clientId) => {
    try {
      const response = await dispatch(
        fetchSoapNotesByClientId(clientId)
      ).unwrap();
      return response;
    } catch (error) {
      console.error("Failed to fetch SOAP notes by client ID:", error);
      return [];
    }
  };

  const handleRowClick = (clientId) => {
    const client = clients.find((client) => client._id === clientId);
    setSelectedClient(client);
    setIsClientOverviewModalOpen(true); // Open client overview modal
  };

  const handleEditClientClick = () => {
    setIsEditClientModalOpen(true); // Open edit modal when 'Edit' button is clicked
  };

  const formattedClients =
    clients?.map((client) => {
      const dateOfBirth = new Date(client.dateOfBirth);
      const formattedDateOfBirth = format(dateOfBirth, "MMMM d, yyyy");
      const age = differenceInYears(new Date(), dateOfBirth);

      const formattedPhoneNumbers = client.phoneNumbers
        .map((phone) => `${phone.number}`)
        .join(", ");

      return {
        ...client,
        dateOfBirth: formattedDateOfBirth,
        age,
        formattedPhoneNumbers,
      };
    }) || []; // Ensure formattedClients is an array
  // Format clients data
  // const formattedClients = clients.map((client) => {
  //   const dateOfBirth = new Date(client.dateOfBirth);
  //   const formattedDateOfBirth = format(dateOfBirth, "MMMM d, yyyy");
  //   const age = differenceInYears(new Date(), dateOfBirth);

  //   // Join all phone numbers into a single string, separated by commas
  //   const formattedPhoneNumbers = client.phoneNumbers
  //     .map((phone) => `${phone.number}`)
  //     .join(", ");

  //   return {
  //     ...client,
  //     dateOfBirth: formattedDateOfBirth,
  //     age,
  //     formattedPhoneNumbers, // Add formatted phone numbers
  //   };
  // });

  return (
    <div>
      {clientStatus === "loading" && <p>Loading clients...</p>}
      {clientStatus === "failed" && (
        <p>
          Error loading clients:{" "}
          {typeof clientError === "object"
            ? JSON.stringify(clientError)
            : clientError}
        </p>
      )}
      {clientStatus === "succeeded" && clients.length === 0 && (
        <p>No clients available</p>
      )}
      {clientStatus === "succeeded" && clients.length > 0 && (
        <Table
          columns={[
            { header: "First Name", accessor: "firstName" },
            { header: "Last Name", accessor: "lastName" },
            { header: "Date of Birth", accessor: "dateOfBirth" },
            { header: "Age", accessor: "age" },
            { header: "Email", accessor: "email" },
            {
              header: "Phone Numbers",
              accessor: "formattedPhoneNumbers",
            },
          ]}
          data={formattedClients}
          onRowClick={handleRowClick} // Opens the overview modal
          actions={[
            {
              label: "Add Client",
              onClick: () => setIsEditClientModalOpen(true),
            },
          ]}
          title="Clients"
          description="A list of all clients associated with the company."
          showPagination={pagination.totalPages > 1}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          onPageChange={(page) =>
            dispatch(fetchClients({ page, limit: pagination.pageSize }))
          }
        />
      )}

      {/* Client Overview Modal */}
      {isClientOverviewModalOpen && selectedClient && (
        <ClientOverviewModal
          client={selectedClient}
          onClose={() => setIsClientOverviewModalOpen(false)}
          fetchInvoicesData={() =>
            fetchInvoicesDataByClientId(selectedClient._id)
          }
          fetchSoapNotesData={() =>
            fetchSoapNotesDataByClientId(selectedClient._id)
          } // Pass the function to fetch SOAP notes by client ID
        />
      )}

      {/* Edit Client Modal */}
      {isEditClientModalOpen && selectedClient && (
        <EditClientModal
          client={selectedClient}
          onClose={() => setIsEditClientModalOpen(false)}
        />
      )}

      {/* Confirm Delete Dialog */}
      {isDeleteClientModalOpen && (
        <ConfirmDeleteDialog
          open={isDeleteClientModalOpen}
          setOpen={setIsDeleteClientModalOpen}
          onDelete={() => dispatch(deleteClient(clientToDelete))}
        />
      )}
    </div>
  );
}
