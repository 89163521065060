import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { searchApi } from "../../store/thunks/searchApiThunk";
import { setQuery } from "../../store/slices/searchApiSlice";

const SearchDropdown = ({
  displayFields,
  onSelection,
  prefix,
  placeholder,
  filter,
  initialSelection,
}) => {
  const dispatch = useDispatch();
  const { results = [], loading, error } = useSelector((state) => state.search);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(initialSelection || null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (searchQuery) {
      const prefixedQuery = prefix ? `${prefix}${searchQuery}` : searchQuery;
      dispatch(setQuery(prefixedQuery));
      dispatch(searchApi(prefixedQuery));
    }
  }, [searchQuery, prefix, dispatch]);

  useEffect(() => {
    if (results.length > 0) {
      console.log("Fetched results:", results);
    }
    if (error) {
      console.error("Error fetching results:", error);
    }
  }, [results, error]);

  useEffect(() => {
    if (initialSelection) {
      setSelectedItem(initialSelection);
    }
  }, [initialSelection]);

  const handleSelection = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    console.log("Selected item:", item);
    if (onSelection) {
      onSelection(item);
    }
  };

  const filteredResults = filter ? results.filter(filter) : results || [];

  return (
    <Listbox value={selectedItem} onChange={handleSelection}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
              onClick={() => setIsOpen(!isOpen)}>
              <span className="inline-flex w-full truncate">
                <span className="truncate">
                  {selectedItem
                    ? displayFields
                        .map((field) => selectedItem[field])
                        .join(", ")
                    : placeholder}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={isOpen}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 w-full max-h-60 overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    console.log("Search query:", e.target.value);
                  }}
                  className="peer block w-full border-0 bg-gray-200 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Search..."
                />
                {loading && (
                  <div className="py-2 pl-3 pr-9 text-gray-500">Loading...</div>
                )}
                {filteredResults.map((item) => (
                  <Listbox.Option
                    key={item._id}
                    value={item}
                    className={({ active }) =>
                      `cursor-default select-none py-2 pl-3 pr-9 ${
                        active ? "bg-indigo-600 text-white" : "text-gray-900"
                      }`
                    }
                    onClick={() => handleSelection(item)}>
                    <div className="flex">
                      <span className="font-normal truncate">
                        {displayFields.map((field) => item[field]).join(", ")}
                      </span>
                    </div>
                    {selectedItem &&
                      displayFields.every(
                        (field) => selectedItem[field] === item[field]
                      ) && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                  </Listbox.Option>
                ))}
                {error && (
                  <div className="py-2 pl-3 pr-9 text-red-500">
                    Error: {error.message}
                  </div>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SearchDropdown;
