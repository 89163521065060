// src/store/thunks/clientsThunk.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig, generateUploadConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (clientData, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/clients`,
        clientData,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async ({ page, limit }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/clients?page=${page}&limit=${limit}`,
        config
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchClientById = createAsyncThunk(
  "clients/fetchClientById",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/clients/${id}`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async ({ id, clientData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.put(
        `${baseURL}/api/clients/${id}`,
        clientData,
        config
      );

      console.log("this is the new client data", id, clientData);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.delete(
        `${baseURL}/api/clients/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
