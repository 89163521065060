import React from "react";
import {
  BookmarkIcon,
  ReceiptRefundIcon,
  XMarkIcon,
  CheckIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  CreditCardIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleBottomCenterTextIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

const CalendarEvent = ({
  appointment,
  onClick,
  onRightClick,
  column,
  maxColumns,
  startHour,
}) => {
  const start = new Date(appointment.start);
  const end = new Date(appointment.end);
  const startOffset =
    ((start.getHours() - startHour) * 60 + start.getMinutes()) / 5;
  const endOffset = ((end.getHours() - startHour) * 60 + end.getMinutes()) / 5;
  const eventDuration = endOffset - startOffset;
  const widthPercentage = 100 / maxColumns;

  const eventColor = appointment.clinician?.calendarColor || "#000FFF";

  // Convert hex color to rgba with opacity
  const hexToRgba = (hex, opacity) => {
    if (!hex) {
      return `rgba(0,0,0,0)`;
    }

    let r = 0,
      g = 0,
      b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    onRightClick(e, appointment);
  };

  const backgroundColor = hexToRgba(eventColor, 0.65);

  // Function to render icon based on status
  const renderStatusIcon = (status) => {
    let IconComponent = null;
    let colorClass = "";
    switch (status) {
      case "Booked":
        IconComponent = BookmarkIcon;
        colorClass = "text-green-500";
        break;
      case "Changed":
        IconComponent = ReceiptRefundIcon;
        colorClass = "text-yellow-500";
        break;
      case "Cancelled":
        IconComponent = XMarkIcon;
        colorClass = "text-red-500";
        break;
      case "Treated":
        IconComponent = CheckCircleIcon;
        colorClass = "text-green-500";
        break;
      case "Tentative":
        IconComponent = QuestionMarkCircleIcon;
        colorClass = "text-yellow-500";
        break;
      case "Invoiced":
        IconComponent = DocumentTextIcon;
        colorClass = "text-green-500";
        break;
      case "Paid":
        IconComponent = CreditCardIcon;
        colorClass = "text-green-500";
        break;
      case "Soap Notes Complete":
        IconComponent = ClipboardDocumentListIcon;
        colorClass = "text-green-500";
        break;
      case "Soap Notes Approved":
        IconComponent = ClipboardDocumentCheckIcon;
        colorClass = "text-green-500";
        break;
      case "Confirmed":
        IconComponent = CheckIcon;
        colorClass = "text-blue-500";
        break;
      case "Comments":
        IconComponent = ChatBubbleBottomCenterTextIcon;
        colorClass = "text-green-500";
        break;
      case "Paid to Clinician":
        IconComponent = BanknotesIcon;
        colorClass = "text-green-500";
        break;
      case "Completed":
        IconComponent = CheckBadgeIcon;
        colorClass = "text-green-500";
        break;
      default:
        return null;
    }
    return (
      <IconComponent
        key={status}
        className={`h-5 w-5 ${colorClass}`}
        data-tip={status}
        data-for={`tooltip-${status}`}
      />
    );
  };

  return (
    <li
      key={appointment._id}
      className="absolute text-white cursor-pointer border overflow-hidden rounded-lg"
      style={{
        backgroundColor: backgroundColor,
        borderColor: eventColor,
        gridRow: `${startOffset + 1} / span ${eventDuration}`,
        gridColumn: `span 1`,
        width: `calc(${widthPercentage}% - 2px)`,
        left: `${column * widthPercentage}%`,
        fontSize: "0.75rem",
      }}
      onClick={() => onClick(appointment)}
      onContextMenu={handleContextMenu} // Add the context menu handler
    >
      <div className="p-2 h-full flex flex-col justify-between">
        <div>
          <div className="flex">
            <div className="flex items-center space-x-1 mr-1">
              {appointment.status.map((status) => renderStatusIcon(status))}
            </div>
            <strong className="text-xs">{appointment.title}</strong>
          </div>
          {appointment.client && (
            <p className="truncate text-xs">{`${appointment.client.firstName} ${appointment.client.lastName}`}</p>
          )}
        </div>
        <div className="flex justify-between">
          <p className="text-xs">
            {`${start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })} - ${end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          </p>
          {appointment.isBlock && (
            <p className="text-xs">
              Block {appointment.blockInfo?.blockNumber} /{" "}
              {appointment.blockInfo?.totalSessions}
            </p>
          )}
        </div>
      </div>
      {appointment.status.map((status) => (
        <Tooltip
          id={`tooltip-${status}`}
          key={status}
          effect="solid"
          place="top"
        />
      ))}
    </li>
  );
};

export default CalendarEvent;
