import React, { useState, useRef, useEffect } from "react";

function GoogleMapsAutocomplete({ onPlaceSelected, inputId }) {
  const [searchInput, setSearchInput] = useState(""); // Added to keep track of the input value
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (window.google) {
      // Check if Google Maps API is loaded
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        document.getElementById(inputId), // Use the provided input ID
        {
          types: ["geocode"], // Limit to address results
          componentRestrictions: { country: "CA" }, // Restrict to Canada (or your desired country)
        }
      );

      autocompleteRef.current.addListener("place_changed", () => {
        const place = autocompleteRef.current.getPlace();
        if (place && onPlaceSelected) {
          onPlaceSelected(place); // Call the callback with the selected place object
        }
      });
    }
  }, [onPlaceSelected, inputId]);

  return (
    <div>
      <input
        id={inputId}
        type="text"
        placeholder="Enter address..."
        value={searchInput} // Bind to the state variable
        onChange={(e) => setSearchInput(e.target.value)} // Update input value on change
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
}

export default GoogleMapsAutocomplete;
