import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./auth/authContext";
import App from "./App";
import Login from "./auth/login";
import Register from "./auth/register";

export default function AppRouter() {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={isAuthenticated ? <App /> : <Navigate to="/login" replace />}
      />
      {/* ... other protected routes ... */}

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />
        }
      />
      <Route path="/register" element={<Register />} />

      {/* Redirect to Dashboard if authenticated */}
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />
        }
      />
    </Routes>
  );
}
