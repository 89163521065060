// src/store/thunks/userThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(
                `${baseURL}/api/users`,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

// Async Thunk for creating a user
export const createUser = createAsyncThunk(
  "users/createUser",
  async (userData, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;  // Assuming token is stored in auth state
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(`${baseURL}/api/users`, userData, config); 
      return response.data; // Return the created user data
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch); 
    }
  }
);
