import React from "react";
import UserBanner from "../../applicationUi/components/UserBanner";

function ReportsPage() {
  return (
    <div>
      This is the Reports content
    </div>
  );
}

export default ReportsPage;
