import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Or your preferred HTTP library

// Async Thunk for creating a user
export const createUser = createAsyncThunk('users/createUser', async (userData, { rejectWithValue }) => {
  try {
    const response = await axios.post('/api/users', userData); // Adjust API endpoint
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    // Add other reducers for user management if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful user creation (e.g., update user list)
        console.log('User created:', action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Error message from the API
      });
  },
});

export default userSlice.reducer;
