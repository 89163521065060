import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAppointmentsByClinicianAndDateRange } from "../../store/thunks/appointmentsThunk";
import {
  createPayroll,
  fetchPayrollsByClinicianAndPayPeriod,
} from "../../store/thunks/payrollThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Listbox, Switch } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function ClinicianPayrollPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);
  const [payperiodAppointments, setPayperiodAppointments] = useState([]);
  const [completedPayrolls, setCompletedPayrolls] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const findClosestPayPeriod = () => {
    const currentDate = new Date();
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(`${startDateString} 2024`);
      const endDate = new Date(`${endDateString} 2024`);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    return closestPeriod;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(findClosestPayPeriod());
  console.log(selectedPeriod);
  console.log("submission date", selectedPeriod.submissionDate);
  console.log("pay day", selectedPeriod.paymentDate);

  useEffect(() => {
    fetchData();
  }, [selectedPeriod, dispatch, user]);

  const fetchData = async () => {
    if (selectedPeriod && user) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date} 2024`));
      setStartDate(start);
      setEndDate(end);

      try {
        const appointmentsResult = await dispatch(
          fetchAppointmentsByClinicianAndDateRange({
            clinicianId: user._id,
            startDate: start,
            endDate: end,
          })
        ).unwrap();
        setPayperiodAppointments(appointmentsResult);
        console.log("INCOMPLETE APPTS:", appointmentsResult);

        const payrollsResult = await dispatch(
          fetchPayrollsByClinicianAndPayPeriod({
            clinicianId: user._id,
            payPeriod: selectedPeriod.payPeriodNumber,
          })
        ).unwrap();
        setCompletedPayrolls(payrollsResult);
        console.log("COMPLETE APPTS:", payrollsResult);

        if (selectedAppointment) {
          const updatedAppointment = appointmentsResult.find(
            (appointment) => appointment._id === selectedAppointment._id
          );
          setSelectedAppointment(updatedAppointment);
        }
      } catch (error) {
        console.error("Fetch data error:", error);
      }
    }
  };

  function refetchInvoices() {
    console.log("refetch invoices reached in clinicianpayrollpage");
    console.log("calling fetchdata in clinicianpayrollpage");
    fetchData();
  }

  const processingAppointments = new Set();

  const handleSaveToPayroll = async (appointment) => {
    if (processingAppointments.has(appointment._id)) {
      return;
    }

    // Add appointment to the set of currently processing appointments
    processingAppointments.add(appointment._id);

    // Check if all required fields are available in the appointment
    if (
      !appointment ||
      !appointment._id ||
      !appointment.clinician ||
      !appointment.clinician._id ||
      !appointment.invoice ||
      !appointment.invoice._id ||
      !appointment.invoice.amount
    ) {
      console.error("Invalid appointment data:", appointment);
      return;
    }

    const payrollData = {
      appointmentId: appointment._id,
      clinicianId: appointment.clinician._id,
      invoiceId: appointment.invoice._id,
      originalAmount: appointment.invoice.amount,
      percentReceived: 45,
      payrollAmount: (appointment.invoice.amount * 0.45).toFixed(2),
      payPeriod: selectedPeriod.payPeriodNumber || null,
      payPeriodDates: selectedPeriod.includeDates || null,
      submitBy: selectedPeriod.submissionDate || null,
      payBy: selectedPeriod.paymentDate || null,
      status: "Pending",
    };

    try {
      await dispatch(createPayroll(payrollData)).unwrap();
      console.log("Saved to payroll:", payrollData);
      fetchData();
    } catch (error) {
      if (error === "Payroll entry already exists for this appointment.") {
        console.log("This appointment is already saved to payroll.");
      } else {
        console.error("Error saving to payroll:", error);
      }
    } finally {
      // Remove the appointment from the set after processing is done
      processingAppointments.delete(appointment._id);
    }
  };

  const normalizeAppointmentData = (data) => {
    let appointment = data.appointmentId ? data.appointmentId : data;
    let invoice = data.invoiceId ? data.invoiceId : data.invoice;

    return { appointment, invoice };
  };

  const handleAppointmentClick = (data) => {
    const { appointment, invoice } = normalizeAppointmentData(data);

    console.log("appointment", appointment);
    console.log("invoice", invoice);

    if (appointment) {
      setSelectedAppointment({ appointment, invoice });
      setIsPayrollSlideOver(true);
    } else {
      console.error("Invalid appointment object:", appointment);
    }
  };

  const filterAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      if (eligibleForPayroll) {
        return hasSoapNotesComplete && hasInvoicePaid;
      }
      if (soapNotesComplete && !hasSoapNotesComplete) {
        return false;
      }
      if (invoicePaid && !hasInvoicePaid) {
        return false;
      }
      return true;
    });
  };

  const calculatePendingAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateAppointmentsCompleted = () => {
    return completedPayrolls.length;
  };

  const calculateEstimatedPay = () => {
    return completedPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const stats = [
    {
      name: "Pay Period",
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },

    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },

    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateAppointmentsCompleted()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Estimated Pay",
      value: `$${calculateEstimatedPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const getStatus = (item) => {
    const hasSoapNotesComplete = item.history.some(
      (historyItem) => historyItem.status === "Soap Notes Complete"
    );
    const hasInvoicePaid = item.invoice && item.invoice.status === "paid";

    let statuses = [];
    if (!hasSoapNotesComplete) {
      statuses.push("SOAP Notes");
    }
    if (!item.invoice) {
      statuses.push("Invoice: No Invoice");
    } else if (!hasInvoicePaid) {
      statuses.push(`Invoice: ${item.invoice.status}`);
    }
    if (hasSoapNotesComplete && hasInvoicePaid) {
      statuses.push("Ready");
      // Automatically save to payroll if not already saved
      if (item.invoice && !item.invoice.isPayrollSaved) {
        handleSaveToPayroll(item);
      }
    }

    const statusClasses = {
      "SOAP Notes": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: No Invoice": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: created": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: sent": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: paid": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: void": "text-orange-700 bg-orange-50 ring-orange-600/20",
      Ready: "text-green-700 bg-green-50 ring-green-600/20",
    };

    return (
      <div className="flex flex-col items-center space-y-1">
        {statuses.map((status, index) => (
          <div
            key={index}
            className={classNames(
              statusClasses[status],
              "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            )}>
            {status}
          </div>
        ))}
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
    fetchData();
  };

  const handlePayrollSlideOverClose = () => {
    setIsPayrollSlideOver(false);
    fetchData();
  };

  return (
    <>
      <div className="py-4">
        {/* Stats */}
        <PayrollStats stats={stats} />

        {/* Pay period selector and toggles */}
        <div className="flex space-x-4 mb-4 justify-around items-center">
          <div className="flex flex-col items-center space-y-2">
            <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
              <div className="flex flex-col items-center">
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                  Select Payroll Period
                </Listbox.Label>
                <div className="relative mt-2 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        aria-hidden="true"
                        className="h-5 w-5 text-gray-400"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {payrollDates.map((period) => (
                      <Listbox.Option
                        key={period.payPeriodNumber}
                        value={period}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-9 ${
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`}>
                              {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </div>
            </Listbox>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Soap Notes Complete
            </label>
            <Switch
              checked={soapNotesComplete}
              onChange={(value) => {
                setSoapNotesComplete(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                soapNotesComplete ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Soap Notes Complete</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  soapNotesComplete ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Invoice Paid
            </label>
            <Switch
              checked={invoicePaid}
              onChange={(value) => {
                setInvoicePaid(value);
                if (!value) setEligibleForPayroll(false);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                invoicePaid ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Invoice Paid</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  invoicePaid ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div className="flex flex-col items-center space-y-2">
            <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Eligible for payroll
            </label>
            <Switch
              checked={eligibleForPayroll}
              onChange={(value) => {
                setEligibleForPayroll(value);
                setSoapNotesComplete(value);
                setInvoicePaid(value);
              }}
              className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                eligibleForPayroll ? "bg-indigo-600" : "bg-gray-200"
              }`}>
              <span className="sr-only">Eligible for Payroll</span>
              <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                  eligibleForPayroll ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </Switch>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Payroll Schedule
            </button>
          </div>
        </div>

        {/* Tables */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-4">
          {/* TABLE FOR PENDING PAYROLL */}
          <div className="border border-gray-300 rounded-lg">
            <PayrollTable
              columns={[
                {
                  header: "Status",
                  accessor: (item) => getStatus(item),
                },
                {
                  header: "Client",
                  accessor: (item) =>
                    item.client
                      ? `${item.client.firstName} ${item.client.lastName}`
                      : "N/A",
                },
                {
                  header: "Service",
                  accessor: "service.description",
                },
                {
                  header: "Service Date",
                  accessor: (item) =>
                    new Date(item.start).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }),
                },
                {
                  header: "Invoice ID",
                  accessor: (item) =>
                    item.invoice ? item.invoice.invoiceId : "No Invoice",
                },
                {
                  header: "Invoice Amount",
                  accessor: (item) =>
                    item.invoice
                      ? `$${item.invoice.amount.toFixed(2)}`
                      : "No Invoice",
                },
                {
                  header: "Invoice Status",
                  accessor: (item) =>
                    item.invoice ? item.invoice.status : "No Invoice",
                },
                {
                  header: "Invoice Created",
                  accessor: (item) =>
                    item.invoice
                      ? new Date(item.invoice.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : "No Invoice",
                },
              ]}
              data={filterAppointments()}
              onRowClick={handleAppointmentClick}
              actions={[]}
              title="Incomplete"
              description={`All your incomplete appointments within the selected payroll period. Complete these by ${getSubmissionDate()} to be paid on ${getScheduledPayDay()}.`}
            />
          </div>

          {/* TABLE FOR COMPLETED PAYROLL */}
          <div className="border border-gray-300 rounded-lg">
            <CompletedPayrollTable
              clinicianId={user._id}
              columns={[
                {
                  header: "Client",
                  accessor: (item) =>
                    item.appointmentId && item.appointmentId.client
                      ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                      : "N/A",
                },
                {
                  header: "Service",
                  accessor: (item) =>
                    item.appointmentId && item.appointmentId.title
                      ? item.appointmentId.title
                      : "N/A",
                },
                {
                  header: "Billed Amount",
                  accessor: (item) => `$${item.originalAmount.toFixed(2)}`,
                },
                {
                  header: "% Received",
                  accessor: (item) => `${item.percentReceived}%`,
                },
                {
                  header: "Payroll Amount",
                  accessor: (item) => `$${item.payrollAmount.toFixed(2)}`,
                },
              ]}
              data={completedPayrolls}
              onRowClick={(item) => handleAppointmentClick(item)}
              title="Completed"
              description={`All your completed appointments within the selected payroll period. These will be paid out on ${getScheduledPayDay()}.`}
            />
          </div>
        </div>
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}

      {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={handlePayrollSlideOverClose}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          submitBy={selectedPeriod.submissionDate}
          payBy={selectedPeriod.paymentDate}
          onUpdateSuccess={fetchData} // Pass fetchData as onUpdateSuccess
          refetchInvoices={refetchInvoices}
        />
      )}
    </>
  );
}
