import React from "react";

const AppointmentButtonContainer = ({ appointment, onButtonClick }) => {
  const isCompletedOrTreated =
    appointment.status.includes("Completed") ||
    appointment.status.includes("Treated");

  const canCancel = !isCompletedOrTreated;
  const canReschedule = !isCompletedOrTreated;
  const canNotify = !isCompletedOrTreated;
  const canCreateSOAPNote = isCompletedOrTreated;
  const canLog = isCompletedOrTreated;

  const currentStatus = appointment.status[0];

  const getNextStatuses = (currentStatus) => {
    const statusTransitions = {
      // Phase 1
      Booked: ["Tentative", "Changed", "Cancelled"],
      Tentative: ["Confirmed", "Changed", "Cancelled"],
      Confirmed: ["Treated", "Changed", "Cancelled"],
      // Phase 2
      Treated: ["Invoiced", "Soap Notes Complete"],
      Invoiced: ["Paid", "Soap Notes Complete"],
      "Soap Notes Complete": ["Soap Notes Approved", "Invoiced"],
      "Soap Notes Approved": ["Paid"],
      Paid: ["Paid to Clinician"],
      "Paid to Clinician": ["Completed"],
      Changed: ["Tentative"],
      Cancelled: [],
      Completed: [],
    };

    return statusTransitions[currentStatus] || [];
  };

  const nextStatuses = getNextStatuses(currentStatus);

  return (
    <div className="flex space-x-2 overflow-x-auto">
      {canCancel && (
        <button
          className="rounded-md bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          onClick={() => onButtonClick("cancel")}>
          Cancel
        </button>
      )}

      {canReschedule && (
        <button
          className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => onButtonClick("reschedule")}>
          Reschedule
        </button>
      )}

      {canNotify && (
        <button
          className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => onButtonClick("notify")}>
          Notify
        </button>
      )}

      {nextStatuses.map((status, index) => (
        <button
          key={index}
          className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => onButtonClick(`status-${status}`)}>
          Mark as: {status}
        </button>
      ))}

      {canCreateSOAPNote && (
        <button
          className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => onButtonClick("createSOAPNote")}>
          SOAP
        </button>
      )}
      {canLog && (
        <button
          className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => onButtonClick("log")}>
          Log
        </button>
      )}
    </div>
  );
};

export default AppointmentButtonContainer;
