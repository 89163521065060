// src/store/thunks/authThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { setLogout } from "../slices/authSlice";

// Async thunk to login a user
console.log("AUTH ROUTE LOADED");

export const loginUserAsync = createAsyncThunk(
  "auth/loginUser",
  async (loginData, { rejectWithValue }) => {
    try {
      console.log("LoginUSerAsync Thunk");
      const { baseURL, config } = generateConfig();

      const response = await axios.post(
        `${baseURL}/api/auth/login`,
        loginData,
        config
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error; // Rethrow the error if it's not from the axios response
      }
      return rejectWithValue(error.response.data); // Reject with the error data
    }
  }
);

export const registerUserAsync = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      console.log("registerUserAsync");
      const { baseURL, config } = generateConfig();

      const response = await axios.post(
        `${baseURL}/api/auth/register`,
        userData,
        config
      );
      return response.data; // Return the response data (including user and token)
    } catch (error) {
      console.log("error: " + error);
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error); // Reject with the specific error message from the API
      } else {
        return rejectWithValue("Registration failed. Please try again."); // Generic error message
      }
    }
  }
);

export const verifyPassword = createAsyncThunk(
  "auth/verifyPassword",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { baseURL, config } = generateConfig();

      const response = await axios.post(
        `${baseURL}/api/auth/verify-password`,
        { email, password },
        config
      );
      return response.data; // Return the response data (including user and token)
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error); // Reject with the specific error message from the API
      } else {
        return rejectWithValue(
          "Password Verification failed. Please try again."
        ); // Generic error message
      }
    }
  }
);

// Async thunk to logout a user
export const logoutUserAsync = createAsyncThunk(
  "auth/logoutUser",
  async (user, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      // Optional: Check if user is logged in at all
      if (!state.auth.user) {
        // Handle case where user is not logged in (optional)
        console.warn("Logout attempted while not logged in.");
        return rejectWithValue("Not logged in");
      }

      // More robust userId check
      if (!user && state.auth.user) {
        console.log("Using user from state");
        user = state.auth.user;
      } else if (!user) {
        // Handle case where userId is still undefined after checking state
        console.error("UserId not found for logout.");
        return rejectWithValue("UserId not found");
      }

      console.log("Auth Thunk User: " + user);

      const response = await axios.post(
        `${baseURL}/api/auth/logout`,
        user,
        config
      );

      if (response.status === 200) {
        // Clear persisted data
        localStorage.removeItem("persist:ClinicFlow");

        // Dispatch the logout action to clear the state
        dispatch(setLogout());
      } else {
        localStorage.removeItem("persist:ClinicFlow");
        throw new Error("Logout failed");
      }
    } catch (error) {
      localStorage.removeItem("persist:ClinicFlow");
      console.error("Error logging out:", error);
      return rejectWithValue("Logout failed");
    }
  }
);
