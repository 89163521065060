import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import ToDoItem from "../../../applicationUi/components/toDoItem";

function ClinicianToDoList({ onReschedule }) {
  const dispatch = useDispatch();
  const { todos, loading, error } = useSelector((state) => state.todos);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch(fetchTodos());
  }, [dispatch]);

  // console.log(JSON.stringify(todos));

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!Array.isArray(todos)) {
    return <div>No To-Do items found.</div>; // Handle case where todos is not an array
  }

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900 ">
        To Do List - {todos.length} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        <ul>
        {todos
          .filter((todo) => todo.appointment.clinician._id === user._id) // Filter todos by clinicianId
          .map((filteredTodo) => (
            <ToDoItem
              key={filteredTodo.appointment._id}
              todo={filteredTodo}
              onReschedule={onReschedule}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ClinicianToDoList;
