import React from "react";
import { FolderIcon, DocumentIcon } from "@heroicons/react/24/outline"; // Import Heroicons

const FileExplorer = ({ files, onFileClick }) => {
  const handleFileClick = (fileName) => {
    onFileClick(fileName);
  };

  const renderTree = (tree, path = "") => {
    return Object.keys(tree).map((key) => {
      const fullPath = `${path}/${key}`;
      if (typeof tree[key] === "string") {
        return (
          <ul key={fullPath}>
            <button
              onClick={() => handleFileClick(fullPath)}
              className="flex items-center space-x-2 pt-2 text-blue-500 hover:underline">
              <DocumentIcon className="h-5 w-5 text-gray-500" />
              <span className="text-sm font-medium">{key}</span>
            </button>
          </ul>
        );
      } else {
        return (
          <ul key={fullPath}>
            <div className="flex items-center space-x-2 pt-5">
              <FolderIcon className="h-5 w-5 text-gray-500" />
              <strong className="text-sm font-medium">{key}</strong>
            </div>
            <ul className="pl-5">{renderTree(tree[key], fullPath)}</ul>
          </ul>
        );
      }
    });
  };

  return (
    <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      {renderTree(files)}
    </div>
  );
};

export default FileExplorer;
