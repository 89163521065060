import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import the CSS
import ical from "ical.js";

const localizer = momentLocalizer(moment);

export default function ClinicSchedule() {
  const [events, setEvents] = useState([]); // Your appointment data

  const clinicians = [
    { id: 1, title: 'Yvonne, Oliveira', icalUrl: "https://ca-alb1.aws.bp-solutions.net:8443/ca_ical/ical?T05faHNz/EfRSBvUt71hBIp9" }
  ];

  const handleSelectSlot = ({ start, end }) => {
    // Open your AppointmentPicker modal here
    // ...
  };

  const handleAddAppointment = (newAppointment) => {
    setEvents([...events, newAppointment]);
    // ... close the modal
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const allEvents = [];
      for (const clinician of clinicians) {
        try {
          const response = await fetch(clinician.icalUrl); // Assuming clinician.icalUrl exists
          const icalData = await response.text();
          const parsedData = ical.parse(icalData);
          const clinicianEvents = parsedData.subComponents
            .filter(comp => comp.name === 'VEVENT')
            .map(event => ({
              title: event.getFirstPropertyValue('summary'),
              start: new Date(event.getFirstPropertyValue('dtstart').toJSDate()),
              end: new Date(event.getFirstPropertyValue('dtend').toJSDate()),
              resourceId: clinician.id, // Associate with the clinician
            }));
          allEvents.push(...clinicianEvents);
        } catch (error) {
          console.error(`Error fetching events for ${clinician.title}:`, error);
        }
      }
      setEvents(allEvents);
    };

    fetchEvents();
  }, []);

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        defaultView="week" // Or 'day', 'month', etc.
        onSelectSlot={handleSelectSlot}
        resources={clinicians} // Array of clinician data
        // ... other props for customization (time slots, headers, etc.)
      />

      {/* <AppointmentPicker onAddAppointment={handleAddAppointment} />  */}
    </>
  );
}
