import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../SoapForm.css";
import {
  saveSoapNote,
  fetchAppointmentsByClinicianId,
  signOffSoapNote,
} from "../../store/thunks/appointmentsThunk";

const TextAreaField = ({
  label,
  name,
  placeholder,
  value,
  readOnly,
  onChange,
}) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold leading-7 text-gray-900">{label}</h3>
    <p className="mt-1 text-sm leading-6 text-gray-600">{placeholder}</p>
    <textarea
      name={name}
      id={name}
      rows="4"
      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={value}
      readOnly={readOnly}
      onChange={onChange}></textarea>
  </div>
);

export default function SOAPFormModal({ onClose, appointment }) {
  const dispatch = useDispatch();
  const selectedClinician = useSelector(
    (state) => state.clinicians.selectedClinician
  );
  const user = useSelector((state) => state.auth.user);

  const [selectedVersion, setSelectedVersion] = useState(
    appointment?.soapNote?.length - 1 || 0
  );
  const [soapData, setSoapData] = useState({
    subjective: "",
    objective: "",
    assessment: "",
    plan: "",
  });
  const [isSignedOff, setIsSignedOff] = useState(false);

  console.log("is signed off?", isSignedOff);
  const refetchAppointments = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (selectedClinician) {
      dispatch(fetchAppointmentsByClinicianId(selectedClinician._id));
    }
  }, [dispatch, selectedClinician]);

  useEffect(() => {
    if (
      appointment &&
      appointment.soapNote &&
      appointment.soapNote.length > 0
    ) {
      const latestVersion = appointment.soapNote.length - 1;
      setSelectedVersion(latestVersion);

      const currentSoapNote = appointment.soapNote[latestVersion];
      setSoapData({
        subjective: currentSoapNote.subjective,
        objective: currentSoapNote.objective,
        assessment: currentSoapNote.assessment,
        plan: currentSoapNote.plan,
      });
      setIsSignedOff(!!currentSoapNote.signedOffBy);
    }
  }, [appointment]);

  const handleSave = (event) => {
    event.preventDefault();

    const soapNote = {
      ...soapData,
      createdBy: user._id,
    };

    console.log("SOAP Data:", soapNote);
    dispatch(saveSoapNote([appointment, soapNote]))
      .unwrap()
      .then((response) => {
        console.log("Save successful:", response);
        refetchAppointments(appointment.clinician._id);
        onClose();
      })
      .catch((error) => {
        console.error("Save failed:", error);
      });
  };

  const handleSignOff = () => {
    const signOffData = {
      appointment,
      user,
      soapNote: soapData,
    };

    dispatch(signOffSoapNote(signOffData))
      .unwrap()
      .then((response) => {
        console.log("Sign-off successful:", response);
        setIsSignedOff(true);
        refetchAppointments(appointment.clinician._id);
        onClose();
      })
      .catch((error) => {
        console.error("Sign-off failed:", error);
      });
  };

  if (!appointment) {
    return null;
  }

  const { client, clinician, service, soapNote, soapNoteFinalized } =
    appointment;
  const currentSoapNote =
    soapNote && soapNote.length > 0 ? soapNote[selectedVersion] : null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-70 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-70 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">
              SOAP Note
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Document the patient's SOAP note. Once created, the SOAP note
              cannot be modified except by an overseeing SLP.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold leading-7 text-gray-900">
              Appointment Details
            </h3>
            <div className="grid grid-cols-3 gap-4">
              {client && (
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  <strong>Client:</strong> {client.firstName} {client.lastName}
                </p>
              )}
              {clinician && (
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  <strong>Clinician:</strong> {clinician.firstName}{" "}
                  {clinician.lastName}
                </p>
              )}
              {service && (
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  <strong>Service:</strong> {service.description}
                </p>
              )}
              <p className="mt-1 text-sm leading-6 text-gray-600">
                <strong>Service date:</strong>{" "}
                {new Date(appointment.start).toLocaleString()}
              </p>
              {currentSoapNote && (
                <>
                  {currentSoapNote.createdAt && currentSoapNote.createdBy && (
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      {selectedVersion === 0 ? (
                        <strong>Note created:</strong>
                      ) : (
                        <strong>Note updated:</strong>
                      )}{" "}
                      {new Date(currentSoapNote.createdAt).toLocaleString()} by{" "}
                      {currentSoapNote.createdBy}
                    </p>
                  )}
                  {soapNoteFinalized && (
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      <strong>Note finalized:</strong>{" "}
                      {new Date(
                        soapNote[soapNote.length - 1].signedOffAt
                      ).toLocaleString()}{" "}
                      by {soapNote[soapNote.length - 1].signedOffBy}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>

          {soapNoteFinalized && (
            <div className="mb-6">
              <label
                htmlFor="version"
                className="block text-sm font-medium leading-6 text-gray-900">
                Version
              </label>
              <select
                id="version"
                name="version"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(Number(e.target.value))}>
                {soapNote.map((note, index) => (
                  <option key={note._id} value={index}>
                    {index === soapNote.length - 1
                      ? `FINAL: Version ${index + 1}`
                      : `Version ${index + 1}`}
                  </option>
                ))}
              </select>
            </div>
          )}

          <TextAreaField
            label="Subjective"
            name="subjective"
            placeholder="Patient's report of their condition."
            value={soapData.subjective}
            readOnly={isSignedOff}
            onChange={(e) =>
              setSoapData({ ...soapData, subjective: e.target.value })
            }
          />
          <TextAreaField
            label="Objective"
            name="objective"
            placeholder="Observable, measurable findings."
            value={soapData.objective}
            readOnly={isSignedOff}
            onChange={(e) =>
              setSoapData({ ...soapData, objective: e.target.value })
            }
          />
          <TextAreaField
            label="Assessment"
            name="assessment"
            placeholder="Diagnosis and analysis."
            value={soapData.assessment}
            readOnly={isSignedOff}
            onChange={(e) =>
              setSoapData({ ...soapData, assessment: e.target.value })
            }
          />
          <TextAreaField
            label="Plan"
            name="plan"
            placeholder="Treatment plan and next steps."
            value={soapData.plan}
            readOnly={isSignedOff}
            onChange={(e) => setSoapData({ ...soapData, plan: e.target.value })}
          />

          <div className="mt-6 flex items-center justify-between gap-x-6">
            {!soapNoteFinalized && (
              <button
                type="button"
                onClick={handleSignOff}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Approve Note
              </button>
            )}
            <div className="flex-grow"></div>
            <div className="flex items-center gap-x-6">
              <button
                type="button"
                onClick={onClose}
                className="text-sm font-semibold leading-6 text-gray-900">
                Exit
              </button>
              {!soapNoteFinalized && (
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
