import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUploads, fetchFile } from "../../store/thunks/uploadsThunk";
import FileExplorer from "../../applicationUi/components/FileExplorer";

export default function ClientUploads({ client }) {
  const dispatch = useDispatch();
  const { files, status, error } = useSelector((state) => state.uploads);

  useEffect(() => {
    if (client?._id) {
      dispatch(fetchUploads(client._id));
    }
  }, [dispatch, client?._id]);

  const handleFileClick = (fileName) => {
    const sanitizedFileName = fileName.replace(/^\//, "");

    dispatch(fetchFile({ clientId: client._id, fileName: sanitizedFileName }))
      .unwrap()
      .then((result) => {
        const url = URL.createObjectURL(
          new Blob([result.fileBlob], { type: result.fileType })
        );

        // Open file in new tab
        const newTab = window.open(url, "_blank");
        if (newTab) {
          newTab.document.write(
            `<iframe src="${url}" style="width:100%; height:100%;" frameborder="0"></iframe>`
          );
          // Clean up the URL object after use
          URL.revokeObjectURL(url);
        }
      })
      .catch((err) => {
        console.error("Failed to fetch file:", err);
      });
  };

  const renderFiles = (files) => {
    const fileTree = {};

    files.forEach((file) => {
      if (typeof file !== "string") {
        console.error("Expected a string for file path but got:", file);
        return; // Skip non-string items
      }

      const parts = file.split("/");
      let current = fileTree;
      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = index === parts.length - 1 ? part : {};
        }
        current = current[part];
      });
    });

    return (
      <FileExplorer
        files={fileTree}
        clientId={client._id}
        onFileClick={handleFileClick}
      />
    );
  };

  return (
    <div>
      <h3 className="block text-sm font-medium text-gray-700">
        Uploaded Documents
      </h3>
      {status === "loading" && <p>Loading uploads...</p>}
      {status === "failed" && <p className="text-red-500">Error: {error}</p>}
      {status === "succeeded" && files.length === 0 && (
        <p className="text-sm font-medium text-gray-700">
          No uploads found for this client.
        </p>
      )}
      {status === "succeeded" && files.length > 0 && renderFiles(files)}
    </div>
  );
}
