import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import EditClientModal from "./EditClientModal";
import Table from "../../applicationUi/components/Table";
import { useDispatch } from "react-redux";
import { fetchUploads, uploadFile } from "../../store/thunks/uploadsThunk";
import ClientUploads from "./ClientUploads";

const tabs = [
  { name: "Client Info", key: "clientInfo" },
  { name: "Invoices", key: "invoices" },
  { name: "Soap Notes", key: "soapNotes" },
  { name: "Upload", key: "upload" },
  { name: "Files", key: "files" },
  { name: "Edit", key: "edit" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ClientOverviewModal({
  client,
  onClose,
  fetchInvoicesData,
  fetchSoapNotesData,
}) {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key);
  const [invoices, setInvoices] = useState([]);
  const [soapNotes, setSoapNotes] = useState([]);
  const [invoiceStatus, setInvoiceStatus] = useState("idle");
  const [soapNoteStatus, setSoapNoteStatus] = useState("idle");
  const [folderName, setFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null); // State to hold the response
  const [newFolder, setNewFolder] = useState(false);
  const [existingFolders, setExistingFolders] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose(); // Close the modal when Escape is pressed
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    if (client?._id) {
      dispatch(fetchUploads(client._id))
        .then((resultAction) => {
          if (fetchUploads.fulfilled.match(resultAction)) {
            const files = resultAction.payload;
            if (files && Array.isArray(files)) {
              // Extract folder names from file paths
              const folderSet = new Set();
              files.forEach((filePath) => {
                const folderName = filePath.split("/")[0];
                folderSet.add(folderName);
              });
              setExistingFolders(Array.from(folderSet));
              console.log("folders", existingFolders);
            } else {
              console.error(
                "Files data is not in expected format:",
                resultAction.payload
              );
            }
          } else {
            console.error("Failed to fetch uploads:", resultAction.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
        });
    }
  }, [client, dispatch]);

  const handleTabChange = async (tabKey) => {
    setSelectedTab(tabKey);

    if (tabKey === "invoices") {
      setInvoiceStatus("loading");
      try {
        const response = await fetchInvoicesData();
        if (response.length === 0) {
          setInvoiceStatus("noData");
        } else {
          const formattedInvoices = response.map((invoice) => ({
            invoiceId: invoice?.invoiceId ?? "N/A",
            clientName: `${invoice?.client?.firstName?.trim() ?? ""} ${
              invoice?.client?.lastName?.trim() ?? ""
            }`,
            clinicianName: `${invoice?.clinician?.firstName?.trim() ?? ""} ${
              invoice?.clinician?.lastName?.trim() ?? ""
            }`,
            formattedServiceDate: format(
              new Date(invoice?.serviceDate ?? new Date()),
              "MMMM d yyyy"
            ),
            formattedAmount: `$${invoice?.amount?.toFixed(2) ?? "0.00"}`,
          }));
          setInvoices(formattedInvoices);
          setInvoiceStatus("succeeded");
        }
      } catch (error) {
        console.error("Failed to fetch invoices:", error);
        setInvoiceStatus("failed");
      }
    } else if (tabKey === "soapNotes") {
      setSoapNoteStatus("loading");
      try {
        const response = await fetchSoapNotesData();
        if (
          response.length === 0 ||
          response.message === "No appointments with SOAP notes found"
        ) {
          setSoapNoteStatus("noData");
        } else {
          const formattedSoapNotes = response.map((appointment) => ({
            title: appointment?.title ?? "N/A",
            serviceDate: format(
              new Date(appointment?.start ?? new Date()),
              "MMMM d yyyy"
            ),
            clinicianName: `${
              appointment?.clinician?.firstName?.trim() ?? ""
            } ${appointment?.clinician?.lastName?.trim() ?? ""}`,
            subjective: appointment?.soapNote?.[0]?.subjective ?? "N/A",
            objective: appointment?.soapNote?.[0]?.objective ?? "N/A",
            assessment: appointment?.soapNote?.[0]?.assessment ?? "N/A",
            plan: appointment?.soapNote?.[0]?.plan ?? "N/A",
          }));
          setSoapNotes(formattedSoapNotes);
          setSoapNoteStatus("succeeded");
        }
      } catch (error) {
        console.error("Failed to fetch SOAP notes:", error);
        setSoapNoteStatus("failed");
      }
    } else if (tabKey === "edit") {
      // Open the EditClientModal when the "Edit" tab is selected
      setIsEditModalOpen(true);
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file && folderName) {
      try {
        console.log("Uploading file:", file.name); // Log file name
        console.log("Folder Name:", folderName); // Log folder name
        const formData = new FormData();
        formData.append("document", file);
        formData.append("folderName", folderName); // Pass folderName to the server

        const resultAction = await dispatch(
          uploadFile({ clientId: client?._id ?? "", formData, folderName })
        );

        if (uploadFile.fulfilled.match(resultAction)) {
          console.log("File uploaded successfully:", resultAction.payload);
          setUploadResponse(resultAction.payload);
          dispatch(fetchUploads(client._id)); // Re-fetch uploads after a successful upload
        } else if (uploadFile.rejected.match(resultAction)) {
          console.error(
            "File upload failed: ",
            resultAction.payload || resultAction.error.message
          );
          alert(resultAction.payload || resultAction.error.message);
        }
      } catch (error) {
        console.error("File upload failed", error);
      }
    } else {
      console.log("No file or folder name provided");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-xl w-3/4 p-6">
        <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
          <div className="md:flex md:items-center md:justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {client?.firstName ?? ""} {client?.lastName ?? ""}
            </h3>
            <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                onClick={onClose}>
                Close
              </button>
            </div>
          </div>
          <div className="mt-4">
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.key}
                    onClick={() => handleTabChange(tab.key)}
                    className={classNames(
                      selectedTab === tab.key
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                    )}>
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Content for the selected tab */}
        <div className="mt-6">
          {selectedTab === "clientInfo" && (
            <div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Full Name
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {client?.firstName?.trim() ?? "N/A"}{" "}
                      {client?.lastName?.trim() ?? "N/A"}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Date of Birth
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {client?.dateOfBirth
                        ? format(new Date(client.dateOfBirth), "MMMM d yyyy")
                        : "N/A"}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Email Address
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {client?.email ?? "N/A"}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {`${client?.address?.streetNumber ?? "N/A"} ${
                        client?.address?.streetName ?? "N/A"
                      }, ${client?.address?.city ?? "N/A"}, ${
                        client?.address?.province ?? "N/A"
                      }, ${client?.address?.postalCode ?? "N/A"}, ${
                        client?.address?.country ?? "N/A"
                      }`}
                    </dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Phone Numbers
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <ul
                        role="list"
                        className="divide-y divide-gray-100 rounded-md">
                        {client?.phoneNumbers?.map((phone, index) => (
                          <li
                            key={index}
                            className="flex items-center justify-between py-2">
                            <span className="text-gray-700">
                              {phone?.type ?? "Unknown"}:{" "}
                              {phone?.number ?? "N/A"}
                            </span>
                          </li>
                        )) ?? "N/A"}
                      </ul>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
          {selectedTab === "invoices" && (
            <div>
              {invoiceStatus === "loading" && (
                <p className="text-gray-700 text-sm">Loading invoices...</p>
              )}
              {invoiceStatus === "failed" && (
                <p className="text-gray-700 text-sm">
                  Failed to load invoices.
                </p>
              )}
              {invoiceStatus === "noData" && (
                <p className="text-gray-700 text-sm">No invoices found.</p>
              )}
              {invoiceStatus === "succeeded" && invoices.length > 0 && (
                <Table
                  columns={[
                    {
                      header: "Invoice ID",
                      accessor: "invoiceId",
                    },
                    {
                      header: "Client Name",
                      accessor: "clientName",
                    },
                    {
                      header: "Clinician Name",
                      accessor: "clinicianName",
                    },
                    {
                      header: "Service Date",
                      accessor: "formattedServiceDate",
                    },
                    {
                      header: "Invoice Amount",
                      accessor: "formattedAmount",
                    },
                  ]}
                  data={invoices}
                  onRowClick={() => {}}
                  title="Invoices"
                  description="A list of all invoices."
                  showPagination={false}
                />
              )}
            </div>
          )}
          {selectedTab === "soapNotes" && (
            <div>
              {soapNoteStatus === "loading" && (
                <p className="text-gray-700 text-sm">Loading SOAP notes...</p>
              )}
              {soapNoteStatus === "failed" && (
                <p className="text-gray-700 text-sm">
                  Failed to load SOAP notes.
                </p>
              )}
              {soapNoteStatus === "noData" && (
                <p className="text-gray-700 text-sm">No SOAP Notes found.</p>
              )}
              {soapNoteStatus === "succeeded" && soapNotes.length > 0 && (
                <Table
                  columns={[
                    {
                      header: "Service",
                      accessor: "title",
                    },
                    {
                      header: "Clinician",
                      accessor: "clinicianName",
                    },
                    {
                      header: "Service Date",
                      accessor: "serviceDate",
                    },
                    {
                      header: "Subjective",
                      accessor: "subjective",
                    },
                    {
                      header: "Objective",
                      accessor: "objective",
                    },
                    {
                      header: "Assessment",
                      accessor: "assessment",
                    },
                    {
                      header: "Plan",
                      accessor: "plan",
                    },
                  ]}
                  data={soapNotes}
                  onRowClick={() => {}}
                  title="SOAP Notes"
                  description="A list of all SOAP notes."
                  showPagination={false}
                />
              )}
            </div>
          )}
          {selectedTab === "upload" && (
            <div>
              {/* Upload Form */}
              <form onSubmit={handleFileUpload}>
                {/* <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Folder Name
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full text-sm text-gray-500"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    placeholder="Enter or select a folder name"
                    required
                  />
                </div> */}

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Folder Name
                  </label>
                  <div className="flex items-center">
                    <select
                      className="mt-1 block w-full text-sm text-gray-500"
                      value={newFolder ? "" : folderName}
                      onChange={(e) => setFolderName(e.target.value)}
                      disabled={newFolder}
                      required>
                      <option value="" disabled>
                        Select an existing folder
                      </option>
                      {existingFolders.map((folder) => (
                        <option key={folder} value={folder}>
                          {folder}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="ml-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                      onClick={() => setNewFolder(!newFolder)}>
                      {newFolder ? "Select Existing Folder" : "New Folder"}
                    </button>
                  </div>
                  {newFolder && (
                    <input
                      type="text"
                      className="mt-1 block w-full text-sm text-gray-500"
                      value={folderName}
                      onChange={(e) => setFolderName(e.target.value)}
                      placeholder="Enter new folder name"
                      required
                    />
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Upload Document
                  </label>
                  <input
                    type="file"
                    className="mt-1 block w-full text-sm text-gray-500"
                    onChange={(e) => setFile(e.target.files[0])}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                  Upload
                </button>
              </form>

              {/* Display Upload Response */}
              {uploadResponse && (
                <div className="mt-4">
                  <p className="text-green-500 text-sm font-medium">
                    {uploadResponse.message}.
                  </p>
                  {/* <p>{JSON.stringify(uploadResponse)}</p> */}
                  {/* Log the entire response object */}
                  {/* <a
                    href={`${process.env.REACT_APP_API_URL}${uploadResponse.filePath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline">
                    {uploadResponse.filePath.split("/").pop()}
                  </a> */}
                </div>
              )}
            </div>
          )}
          {selectedTab === "files" && (
            <div>
              <ClientUploads client={client} />
            </div>
          )}
          {selectedTab === "edit" && isEditModalOpen && (
            <div>
              <EditClientModal
                client={client}
                onCancel={() => setIsEditModalOpen(false)}
                isInline
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
