import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { fetchAppointmentsByClinicianAndDateRange } from "../../store/thunks/appointmentsThunk";
import {
  fetchPayrollsByClinicianAndPayPeriod,
  fetchPayrollsByClinicianStatusAndPayPeriod,
} from "../../store/thunks/payrollThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function HRPayrollPage({
  clinicianId,
  selectedPeriod,
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();

  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [payperiodAppointments, setPayperiodAppointments] = useState([]);
  const [completedPayrolls, setCompletedPayrolls] = useState([]);
  const [carryoverPayrolls, setCarryoverPayrolls] = useState([]);

  const findClosestPayPeriod = () => {
    const currentDate = new Date();
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(`${startDateString} 2024`);
      const endDate = new Date(`${endDateString} 2024`);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    return closestPeriod;
  };

  const fetchData = () => {
    if (selectedPeriod) {
      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date} 2024`));
      setStartDate(start);
      setEndDate(end);

      dispatch(
        fetchAppointmentsByClinicianAndDateRange({
          clinicianId: clinicianId,
          startDate: start,
          endDate: end,
        })
      )
        .then((result) => {
          setPayperiodAppointments(result.payload);
          console.log("PENDING APPOINTMENTS:", result.payload);
        })
        .catch((error) => {
          console.error("Dispatch error:", error);
        });

      dispatch(
        fetchPayrollsByClinicianStatusAndPayPeriod({
          clinicianId: clinicianId,
          payPeriod: selectedPeriod.payPeriodNumber,
          statuses: ["Pending", "Ready", "Paid"], // Pass statuses as an array
        })
      )
        .then((result) => {
          if (Array.isArray(result.payload)) {
            setCompletedPayrolls(result.payload);
          } else {
            setCompletedPayrolls([]);
            console.error("Expected an array but received:", result.payload);
          }
          console.log("RDY FOR PAYROLL APPTS", result.payload);
        })
        .catch((error) => {
          console.error("Fetch Payrolls Error:", error);
        });

      dispatch(
        fetchPayrollsByClinicianStatusAndPayPeriod({
          clinicianId: clinicianId,
          payPeriod: selectedPeriod.payPeriodNumber,
          statuses: ["Carryover"],
        })
      )
        .then((result) => {
          if (Array.isArray(result.payload)) {
            setCarryoverPayrolls(result.payload);
          } else {
            setCarryoverPayrolls([]);
            console.error("Expected an array but received:", result.payload);
          }
          console.log("CARRYOVER APPTS", result.payload);
        })
        .catch((error) => {
          console.error("Fetch Payrolls Error:", error);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, selectedPeriod, selectedClinician]);

  const normalizeAppointmentData = (data) => {
    let appointment = data.appointmentId ? data.appointmentId : data;
    let invoice = data.invoiceId ? data.invoiceId : data.invoice;
    let payrollId = data._id;
    return { appointment, invoice, payrollId };
  };

  const handleAppointmentClick = (data) => {
    const { appointment, invoice, payrollId } = normalizeAppointmentData(data);

    console.log("appointment", appointment);
    console.log("invoice", invoice);
    console.log("payrollid", payrollId);

    if (appointment) {
      setSelectedAppointment({ appointment, invoice, payrollId });
      setIsPayrollSlideOver(true);
    } else {
      console.error("Invalid appointment object:", appointment);
    }
  };

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
  };

  const filterAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      if (eligibleForPayroll) {
        return hasSoapNotesComplete && hasInvoicePaid;
      }
      if (soapNotesComplete && !hasSoapNotesComplete) {
        return false;
      }
      if (invoicePaid && !hasInvoicePaid) {
        return false;
      }
      return true;
    });
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const calculateIncompleteAppointments = () => {
    return payperiodAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calcuateCompletedAppointments = () => {
    return completedPayrolls.length;
  };

  const calculateEstimatedPay = () => {
    if (!Array.isArray(completedPayrolls)) {
      return "0.00";
    }
    return completedPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const stats = [
    {
      name: "Pay Period",
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Incomplete Appointments",
      value: `${calculateIncompleteAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calcuateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Estimated Pay",
      value: `$${calculateEstimatedPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 md:pl-30">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-4xl">
                  {/* <div className="py-4"> */}
                  <div className="px-4 flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <PayrollStats stats={stats} />
                    {/* Pay period selector and toggles */}
                    <div className="flex space-x-4 mb-4 justify-around items-center">
                      {/* <div className="flex flex-col items-center space-y-2">
                        <Listbox
                          value={selectedPeriod}
                          onChange={setSelectedPeriod}>
                          <div className="flex flex-col items-center">
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                              Select Payroll Period
                            </Listbox.Label>
                            <div className="relative mt-2 w-full">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {payrollDates.map((period) => (
                                  <Listbox.Option
                                    key={period.payPeriodNumber}
                                    value={period}
                                    className={({ active, selected }) =>
                                      `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                        active
                                          ? "bg-indigo-600 text-white"
                                          : "text-gray-900"
                                      }`
                                    }>
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-semibold"
                                              : "font-normal"
                                          }`}>
                                          {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                                        </span>
                                        {selected && (
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          </div>
                        </Listbox>
                      </div> */}

                      {/* <div className="flex flex-col items-center space-y-2">
                        <Listbox>
                          <div className="flex flex-col items-center">
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                              Select Clinician
                            </Listbox.Label>
                            <SearchDropdown
                              prefix=":user:"
                              displayFields={["firstName", "lastName", "email"]}
                              onSelection={(clinician) => {
                                console.log("Selected clinician:", clinician);
                                setSelectedClinician(clinician);
                              }}
                              placeholder="Select a clinician"
                            />
                          </div>
                        </Listbox>
                      </div> */}
                      <p>
                        {selectedPeriod.includeDates} {clinicianId}
                      </p>
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Soap Notes Complete
                        </label>
                        <Switch
                          checked={soapNotesComplete}
                          onChange={(value) => {
                            setSoapNotesComplete(value);
                            if (!value) setEligibleForPayroll(false);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            soapNotesComplete ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Soap Notes Complete</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              soapNotesComplete
                                ? "translate-x-6"
                                : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Invoice Paid
                        </label>
                        <Switch
                          checked={invoicePaid}
                          onChange={(value) => {
                            setInvoicePaid(value);
                            if (!value) setEligibleForPayroll(false);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            invoicePaid ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Invoice Paid</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              invoicePaid ? "translate-x-6" : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div className="flex flex-col items-center space-y-2">
                        <label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                          Eligible for payroll
                        </label>
                        <Switch
                          checked={eligibleForPayroll}
                          onChange={(value) => {
                            setEligibleForPayroll(value);
                            setSoapNotesComplete(value);
                            setInvoicePaid(value);
                          }}
                          className={`relative inline-flex items-center h-6 rounded-full w-11 ${
                            eligibleForPayroll ? "bg-indigo-600" : "bg-gray-200"
                          }`}>
                          <span className="sr-only">Eligible for Payroll</span>
                          <span
                            className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${
                              eligibleForPayroll
                                ? "translate-x-6"
                                : "translate-x-1"
                            }`}
                          />
                        </Switch>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() =>
                            setShowPayrollSchedule(!showPayrollSchedule)
                          }
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          View Payroll Schedule
                        </button>
                      </div>
                    </div>

                    <div>
                      {/* TABLE FOR COMPLETED PAYROLL */}
                      <div className="border border-gray-300 rounded-lg">
                        <CompletedPayrollTable
                          columns={[
                            {
                              header: "Payroll Status",
                              accessor: (item) => `${item.status}`,
                            },
                            {
                              header: "Client",
                              accessor: (item) =>
                                item.appointmentId.client
                                  ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                                  : "N/A",
                            },
                            {
                              header: "Service",
                              accessor: (item) =>
                                item.appointmentId && item.appointmentId.title
                                  ? item.appointmentId.title
                                  : "N/A",
                            },
                            {
                              header: "Service Date",
                              accessor: (item) => {
                                if (
                                  item.appointmentId &&
                                  item.appointmentId.start
                                ) {
                                  const date = new Date(
                                    item.appointmentId.start
                                  );
                                  const optionsDate = {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  };
                                  const optionsTime = {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  };
                                  return `${date.toLocaleDateString(
                                    "en-US",
                                    optionsDate
                                  )}, ${date.toLocaleTimeString(
                                    "en-US",
                                    optionsTime
                                  )}`;
                                } else {
                                  return "N/A";
                                }
                              },
                            },
                            {
                              header: "Billed Amount",
                              accessor: (item) =>
                                `$${item.originalAmount.toFixed(2)}`,
                            },
                            {
                              header: "% Received",
                              accessor: (item) => `${item.percentReceived}%`,
                            },
                            {
                              header: "Payroll Amount",
                              accessor: (item) =>
                                `$${item.payrollAmount.toFixed(2)}`,
                            },
                          ]}
                          data={completedPayrolls}
                          // onRowClick={handleAppointmentClick}
                          onRowClick={(item) => handleAppointmentClick(item)}
                          clinicianId={null}
                          title={`Completed Appointments [${calcuateCompletedAppointments()}]`}
                          description="Select a clinician to view a list of their completed appointments, per pay period."
                        />
                      </div>

                      {/* TABLE FOR PENDING PAYROLL */}
                      <div className="border border-gray-300 rounded-lg mt-6">
                        <PayrollTable
                          columns={[
                            {
                              header: "Appointment Status",
                              accessor: (item) => `${item.status}`,
                            },
                            {
                              header: "Client",
                              accessor: (item) =>
                                item.client
                                  ? `${item.client.firstName} ${item.client.lastName}`
                                  : "N/A",
                            },
                            {
                              header: "Service",
                              accessor: (item) =>
                                item.service ? item.service.description : "N/A",
                            },
                            {
                              header: "Service Date",
                              accessor: (item) =>
                                item.start
                                  ? new Date(item.start).toLocaleDateString(
                                      "en-US",
                                      {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                      }
                                    )
                                  : "N/A",
                            },
                            {
                              header: "Invoice ID",
                              accessor: (item) =>
                                item.invoice
                                  ? item.invoice.invoiceId
                                  : "No Invoice",
                            },
                            {
                              header: "Invoice Amount",
                              accessor: (item) =>
                                item.invoice
                                  ? `$${item.invoice.amount.toFixed(2)}`
                                  : "No Invoice",
                            },
                            {
                              header: "Invoice Status",
                              accessor: (item) =>
                                item.invoice
                                  ? item.invoice.status
                                  : "No Invoice",
                            },
                            {
                              header: "Invoice Created",
                              accessor: (item) =>
                                item.invoice
                                  ? new Date(
                                      item.invoice.createdAt
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })
                                  : "No Invoice",
                            },
                          ]}
                          data={filterAppointments()}
                          onRowClick={handleAppointmentClick}
                          actions={[]}
                          title={`Pending Appointments [${calculateIncompleteAppointments()}]`}
                          description="Select a clinician to view a list of their incomplete appointments, per pay period."
                        />

                        <CompletedPayrollTable
                          columns={[
                            {
                              header: "Payroll Status",
                              accessor: (item) => `${item.status}`,
                            },
                            {
                              header: "Client",
                              accessor: (item) =>
                                item.appointmentId.client
                                  ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                                  : "N/A",
                            },
                            {
                              header: "Service",
                              accessor: (item) =>
                                item.appointmentId && item.appointmentId.title
                                  ? item.appointmentId.title
                                  : "N/A",
                            },
                            {
                              header: "Service Date",
                              accessor: (item) => {
                                if (
                                  item.appointmentId &&
                                  item.appointmentId.start
                                ) {
                                  const date = new Date(
                                    item.appointmentId.start
                                  );
                                  const optionsDate = {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  };
                                  const optionsTime = {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  };
                                  return `${date.toLocaleDateString(
                                    "en-US",
                                    optionsDate
                                  )}, ${date.toLocaleTimeString(
                                    "en-US",
                                    optionsTime
                                  )}`;
                                } else {
                                  return "N/A";
                                }
                              },
                            },
                            {
                              header: "Billed Amount",
                              accessor: (item) =>
                                `$${item.originalAmount.toFixed(2)}`,
                            },
                            {
                              header: "% Received",
                              accessor: (item) => `${item.percentReceived}%`,
                            },
                            {
                              header: "Payroll Amount",
                              accessor: (item) =>
                                `$${item.payrollAmount.toFixed(2)}`,
                            },
                          ]}
                          data={carryoverPayrolls}
                          // onRowClick={handleAppointmentClick}
                          onRowClick={(item) => handleAppointmentClick(item)}
                          clinicianId={null}
                          title={`Carryover Appointments [${calcuateCompletedAppointments()}]`}
                          description="Select a clinician to view a list of their completed appointments, per pay period."
                        />
                      </div>
                    </div>
                  </div>
                  {showPayrollSchedule && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                      onClick={handleCloseModal}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") handleCloseModal();
                      }}>
                      <div
                        className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
                        onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={handleCloseModal}
                          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                        <PayrollScheduleTable />
                      </div>
                    </div>
                  )}
                  {isPayrollSlideOver && selectedAppointment && (
                    <PayrollSlideOver
                      isOpen={isPayrollSlideOver}
                      appointment={selectedAppointment}
                      onClose={() => setIsPayrollSlideOver(false)}
                      selectedPeriod={selectedPeriod.payPeriodNumber}
                      selectedPeriodDates={selectedPeriod.includeDates}
                      isHr={true}
                      onUpdateSuccess={fetchData}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
