import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TwoColumnDescriptionList from "./TwoColumnDescriptionList";
import StepsVerticalList from "./StepsVerticalList";
import { useDispatch } from "react-redux";
import {
  fetchAppointmentById,
  fetchAppointmentsByClinicianId,
  fetchAppointmentsByClientId,
} from "../../store/thunks/appointmentsThunk";
import FutureAppointments from "./FutureAppointments";
import InvoiceModal from "./InvoiceModal";

export default function WideSidePanel({
  title,
  open,
  onClose,
  appointment,
  onAppointmentClick,
  onReschedule,
}) {
  const dispatch = useDispatch();
  const [currentAppointment, setCurrentAppointment] = useState(appointment);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showInvoiceModal, setShowInvoiceModal] = useState();

  useEffect(() => {
    setCurrentAppointment(appointment);
    console.log("Appointment", appointment);
  }, [appointment]);

  // useEffect to fetch future appointments once when the component mounts
  useEffect(() => {
    if (appointment && appointment.client && appointment.client._id) {
      fetchFutureAppointments();
    }
  }, []);

  const refetchAppointments = (clinicianId) => {
    dispatch(fetchAppointmentsByClinicianId(clinicianId));
  };

  const refetchAppointment = async () => {
    try {
      const updatedAppointment = await dispatch(
        fetchAppointmentById(appointment._id)
      ).unwrap();
      setCurrentAppointment(updatedAppointment);
      refetchAppointments(appointment.clinician._id);
    } catch (error) {
      console.error("Failed to refetch appointment:", error);
    }
  };

  const fetchFutureAppointments = async () => {
    try {
      const futureAppointments = await dispatch(
        fetchAppointmentsByClientId(appointment.client._id)
      ).unwrap();
      setFutureAppointments(futureAppointments);
      console.log("Future appts for this client", futureAppointments);
      refetchAppointments(appointment.clinician._id);
    } catch (error) {
      console.error("Failed to refetch appointment:", error);
    }
  };

  const handleRescheduleClick = () => {
    onClose();
    onReschedule(currentAppointment);
  };

  const handleAppointmentCancel = () => {
    refetchAppointment();
  };

  const handleEmailSent = () => {
    refetchAppointment();
  };

  const handleShowInvoiceModal = () => {
    setShowInvoiceModal(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 " onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden ">
          <div className="absolute inset-0 overflow-hidden">
            <div className=" pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-[70vw] h-full flex flex-col bg-white shadow-xl">
                  <div className="flex-1 flex flex-col overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between pt-4">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                        <div className="lg:col-span-4 bg-white px-4 pb-2">
                          <TwoColumnDescriptionList
                            type="client"
                            client={appointment.client}
                          />
                        </div>
                        <div className="lg:col-span-4 bg-white px-4 pb-2">
                          <button
                            className="rounded-md bg-indigo-600 px-2.5 py-1.5 mb-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={handleShowInvoiceModal}>
                            Invoices
                          </button>
                        </div>
                        <div className="lg:col-span-2 bg-white pl-4">
                          <TwoColumnDescriptionList
                            type="appointment"
                            appointment={currentAppointment}
                            onReschedule={handleRescheduleClick}
                            refetchAppointments={refetchAppointment}
                            onAppointmentCancel={handleAppointmentCancel} // Pass the callback
                            onEmailSent={handleEmailSent}
                          />
                          <FutureAppointments
                            appointments={futureAppointments}
                            onAppointmentClick={onAppointmentClick}
                          />
                        </div>
                        <div className="lg:col-span-2 bg-white pl-2 pr-4 h-full">
                          <StepsVerticalList
                            appointment={currentAppointment}
                            refetchAppointment={refetchAppointment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {showInvoiceModal && (
                    <div>
                      <InvoiceModal appointment={currentAppointment} />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
