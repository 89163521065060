// src/store/slices/appointmentsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAppointmentsList,
  fetchAppointmentsByClientId,
  fetchAppointmentsByClinicianId,
  fetchAppointmentsByClinicianAndDateRange,
  saveSoapNote,
  updateSoapNote,
  signOffSoapNote,
  fetchAppointmentsByDate,
  fetchAppointmentsByDateRange,
  saveAppointment,
  createAppointment,
  notifyClientByEmail,
  checkForConflicts,
} from "../thunks/appointmentsThunk";

const initialState = {
  appointments: [],
  loading: false,
  error: null,
};

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Appointments List
      .addCase(fetchAppointmentsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsList.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Client ID
      .addCase(fetchAppointmentsByClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByClientId.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByClientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Clinician ID
      .addCase(fetchAppointmentsByClinicianId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByClinicianId.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByClinicianId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Clinician ID and date range (for payroll)
      .addCase(fetchAppointmentsByClinicianAndDateRange.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAppointmentsByClinicianAndDateRange.fulfilled,
        (state, action) => {
          state.loading = false;
          state.appointments = action.payload;
        }
      )
      .addCase(
        fetchAppointmentsByClinicianAndDateRange.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )

      // Save SOAP Note
      .addCase(saveSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(saveSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update SOAP Note
      .addCase(updateSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(updateSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Sign off SOAP Note
      .addCase(signOffSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOffSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(signOffSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Date
      .addCase(fetchAppointmentsByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Date Range
      .addCase(fetchAppointmentsByDateRange.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Save Appointment
      .addCase(saveAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveAppointment.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        } else {
          state.appointments.push(action.payload);
        }
      })
      .addCase(saveAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Appointment
      .addCase(createAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments.push(action.payload);
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // notifyClientByEmail
      .addCase(notifyClientByEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(notifyClientByEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments.push(action.payload);
      })
      .addCase(notifyClientByEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(checkForConflicts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkForConflicts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.conflicts = action.payload;
      })
      .addCase(checkForConflicts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default appointmentsSlice.reducer;
