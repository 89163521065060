import React from "react";
import "./tooltip.css";

const lightColors = {
  Booked: "gray",
  Changed: "yellow",
  Cancelled: "red",
  Tentative: "#ffb514",
  Confirmed: "blue",
  Treated: "#23d117", // Lighter green color
  Completed: "#23d117", // Lighter green color
  Invoiced: "yellow",
  Paid: "green",
  "Payment Failed": "red",
  "Soap Notes Complete": "green",
  "Clinician Payment Pending": "yellow",
  "Paid to Clinician": "#23d117", // Lighter green color
  incomplete: "gray",
  inProgress: "yellow",
  complete: "#23d117", // Lighter green color
  error: "red",
};

const getStatusFromHistory = (history, statusOptions) => {
  if (!history) return "incomplete";
  for (const status of statusOptions) {
    const latestStatus = history.find((h) => h.status === status);
    if (latestStatus) return latestStatus.status;
  }
  return "incomplete";
};

const StatusLights = ({ appointment }) => {
  const history = appointment?.history || [];

  const lights = [
    {
      label: "Appointment",
      status: getStatusFromHistory(history, [
        "Cancelled", // Prioritize Cancelled first
        "Completed",
        "Confirmed",
        "Tentative",
        "Changed",
        "Booked",
      ]),
    },
    {
      label: "Treated",
      status: history.some((h) => h.status === "Treated")
        ? "Treated"
        : "Not Treated",
    },
    {
      label: "Invoicing",
      status: getStatusFromHistory(history, [
        "Payment Failed",
        "Paid",
        "Invoiced",
      ]),
    },
    {
      label: "Soap Notes",
      status: history.some((h) => h.status === "Soap Notes Complete")
        ? "complete"
        : "incomplete",
    },
    {
      label: "Clinician Payment",
      status: getStatusFromHistory(history, [
        "Paid to Clinician",
        "Clinician Payment Pending",
      ]),
    },
  ];

  return (
    <div className="flex rounded-lg p-1.5 bg-gray-200">
      {lights.map((light) => (
        <div
          key={light.label}
          className="tooltip-container flex items-center mx-0.5">
          <div
            className={`w-2 h-2 rounded-full`}
            style={{ backgroundColor: lightColors[light.status] || "gray" }}
          />
          <span className="tooltip-text">
            {light.label}: {light.status}
          </span>
        </div>
      ))}
    </div>
  );
};

export default StatusLights;
